define("omap/routes/user", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "emberfire/mixins/realtime-route"], function (_exports, _authenticatedRouteMixin, _realtimeRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _realtimeRoute.default, {
    authenticationRoute: 'check-in',
    firebaseApp: Ember.inject.service(),

    model() {
      const uid = this.session.data.authenticated.user.uid; // await this.store.findRecord('user', uid);
      // ! HAck
      // we want to load `projects` so we can display them right away.
      // *emberfire has currenUserIDtly no good `include` support
      // await this.store
      //   .query('project', { query: ref => ref.where('user', '==', uid).limit(10) })
      //  return {user, projects}
      // return RSVP.hash({
      //   user: this.store.findRecord( 'user', uid),
      //   // projects: this.store
      //   //   .query('project', { query: ref => ref.where('user', '==', uid).limit(10) })
      // })
      // return this.store.peekRecord(

      const currenUserID = this.session.data.authenticated.user.uid;
      console.log('current useer id', currenUserID);
      return this.store.query('marker', {
        filter: {
          user: uid
        }
      } // {
      //   reload: true,
      //   // include: 'projects'
      // }
      );
    }

  });

  _exports.default = _default;
});