define("omap/routes/user/edit-images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserEditImagesRoute extends Ember.Route {}

  _exports.default = UserEditImagesRoute;
});