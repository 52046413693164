define("omap/helpers/markdowner", ["exports", "remarkable"], function (_exports, _remarkable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Render html from markdown input
   *
   * @public
   * @function markdowner
   * @param {string} markdownInput The markdown we wanto to process.
   * @returns {string} The ready html.
   */
  function markdowner([markdownInput]) {
    const md = new _remarkable.Remarkable();
    let result;

    try {
      result = md.render(markdownInput);
    } catch (error) {
      result = "ERROR in Your Markdown 💥";
    }

    return result;
  }

  var _default = Ember.Helper.helper(markdowner);

  _exports.default = _default;
});