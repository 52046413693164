define("omap/serializers/user", ["exports", "emberfire/serializers/firestore"], function (_exports, _firestore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/serializers/product.js
  var _default = _firestore.default.extend({// attrs: {
    //   projects: { embedded: 'always' }
    // }
  });

  _exports.default = _default;
});