define("omap/components/-private-api/addon-factory", ["exports", "ember-google-maps/components/-private-api/addon-factory"], function (_exports, _addonFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _addonFactory.default;
    }
  });
});