define("omap/controllers/light-styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // https://htmlcolorcodes.com/
  // #17A589
  // #17A589
  // more options https://snazzymaps.com/explore?page=3
  const light = [{
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{
      // color: '#545677' // grey
      // color: '#17A589' // green
      // color: '#F1C40F' // yellow
      color: '#A3E4D7' // lightish green

    }]
  }, {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{
      color: '#fffbf7'
    }]
  }, {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{
      color: '#545677'
    }, {
      lightness: 40
    }]
  }, {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{
      visibility: 'off'
    }, {
      color: '#406d80'
    }]
  }, {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{
      color: '#545677'
    }]
  }, {
    elementType: 'labels.text',
    stylers: [{
      visibility: 'on'
    }]
  }, {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [{
      visibility: 'on'
    }, {
      weight: 0.6
    }, {
      color: '#1a3541'
    }]
  }, {
    elementType: 'labels.icon',
    stylers: [{
      visibility: 'off'
    }]
  }, {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{
      visibility: 'off'
    }]
  }];
  var _default = light;
  _exports.default = _default;
});