define("omap/templates/user/edit-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yt+sftQH",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"user/marker\",[],[[\"@marker\"],[[32,1]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "omap/templates/user/edit-marker.hbs"
    }
  });

  _exports.default = _default;
});