define("omap/serializers/application", ["exports", "emberfire/serializers/firestore"], function (_exports, _firestore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FirestoreSerializer extends _firestore.default {
    normalizeCreateRecordResponse(store, primaryModelClass) {
      let result = super.normalizeCreateRecordResponse(...arguments);
      console.log('result in serializer', result);
      result.data.type = primaryModelClass.modelName;
      console.log('serializing in FirestoreSerializer');
      return result;
    } // serializeAttribute(snapshot, json, key, attributes) {
    //     console.log('serialize attrs', { snapshot, json, key, attributes });
    //   if (key == "updatedAt") {
    //     snapshot._attributes.createdAt = "lkjölkjöl"
    //   }
    //   if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
    //     console.log('if running' );
    //     this._super(snapshot, json, key, attributes);
    //   }
    // }


  }

  _exports.default = FirestoreSerializer;
});