define("omap/services/custom-markercluster-patched", ["ember-concurrency"], function (_emberConcurrency) {
  "use strict";

  // *********************************************************************
  // Monkey patching Leaflet Popup for better UX
  L.Popup.include({
    // seem not necessary any more,
    // now that we have proper cluster calculation in place
    XXX_adjustPan: function () {
      if (!this.options.autoPan) {
        return;
      }

      if (this._map._panAnim) {
        this._map._panAnim.stop();
      }

      var map = this._map,
          marginBottom = parseInt(L.DomUtil.getStyle(this._container, 'marginBottom'), 10) || 0,
          containerHeight = this._container.offsetHeight + marginBottom,
          containerWidth = this._containerWidth,
          layerPos = new L.Point(this._containerLeft, -containerHeight - this._containerBottom);

      layerPos._add(L.DomUtil.getPosition(this._container));

      var containerPos = map.layerPointToContainerPoint(layerPos),
          padding = L.point(this.options.autoPanPadding),
          paddingTL = L.point(this.options.autoPanPaddingTopLeft || padding),
          paddingBR = L.point(this.options.autoPanPaddingBottomRight || padding),
          size = map.getSize(),
          dx = 0,
          dy = 0;

      if (containerPos.x + containerWidth + paddingBR.x > size.x) {
        // right
        dx = containerPos.x + containerWidth - size.x + paddingBR.x;
      }

      if (containerPos.x - dx - paddingTL.x < 0) {
        // left
        dx = containerPos.x - paddingTL.x;
      }

      if (containerPos.y + containerHeight + paddingBR.y > size.y) {
        // bottom
        dy = containerPos.y + containerHeight - size.y + paddingBR.y;
      }

      if (containerPos.y - dy - paddingTL.y < 0) {
        // top
        dy = containerPos.y - paddingTL.y;
      }

      if (dx || dy) {
        if (map._zoom <= 4) {
          const posOfPopupInPX = map.project(this._latlng, 5);
          const calculatedNewCenter = {
            x: posOfPopupInPX.x,
            y: posOfPopupInPX.y - containerHeight / 2
          };
          const posOfCenterInLatLng = map.unproject(calculatedNewCenter, 5);
          map.flyTo(posOfCenterInLatLng, 5);
        } else {
          map.fire('autopanstart').panBy([dx, dy]);
        }
      }
    }
  }) - // *********************************************************************
  // Monkey patching leaflet.markercluster
  // - MarkerCluster
  // - MarkerClusterGroup
  L.MarkerCluster.include({
    _animationSpiderfy: function (childMarkers, positions) {
      var me = this,
          group = this._group,
          map = group._map,
          fg = group._featureGroup,
          thisLayerLatLng = this._latlng,
          thisLayerPos = map.latLngToLayerPoint(thisLayerLatLng),
          svg = L.Path.SVG,
          legOptions = L.extend({}, this._group.options.spiderLegPolylineOptions),
          // Copy the options so that we can modify them for animation.
      finalLegOpacity = legOptions.opacity,
          i,
          m,
          leg,
          legPath,
          legLength,
          newPos;

      if (finalLegOpacity === undefined) {
        finalLegOpacity = L.MarkerClusterGroup.prototype.options.spiderLegPolylineOptions.opacity;
      }

      if (svg) {
        // If the initial opacity of the spider leg is not 0 then it appears before the animation starts.
        legOptions.opacity = 0; // Add the class for CSS transitions.

        legOptions.className = (legOptions.className || '') + ' leaflet-cluster-spider-leg';
      } else {
        // Make sure we have a defined opacity.
        legOptions.opacity = finalLegOpacity;
      }

      group._ignoreMove = true; // Add markers and spider legs to map, hidden at our center point.
      // Traverse in ascending order to make sure that inner circleMarkers are on top of further legs. Normal markers are re-ordered by newPosition.
      // The reverse order trick no longer improves performance on modern browsers.

      for (i = 0; i < childMarkers.length; i++) {
        m = childMarkers[i];
        newPos = map.layerPointToLatLng(positions[i]); // Add the leg before the marker, so that in case the latter is a circleMarker, the leg is behind it.

        leg = new L.Polyline([thisLayerLatLng, newPos], legOptions);
        map.addLayer(leg);
        m._spiderLeg = leg; // Explanations: https://jakearchibald.com/2013/animated-line-drawing-svg/
        // In our case the transition property is declared in the CSS file.

        if (svg) {
          legPath = leg._path;
          legLength = legPath.getTotalLength() + 0.1; // Need a small extra length to avoid remaining dot in Firefox.

          legPath.style.strokeDasharray = legLength; // Just 1 length is enough, it will be duplicated.

          legPath.style.strokeDashoffset = legLength;
        } // If it is a marker, add it now and we'll animate it out


        if (m.setZIndexOffset) {
          m.setZIndexOffset(1000000); // Make normal markers appear on top of EVERYTHING
        }

        if (m.clusterHide) {
          m.clusterHide();
        } // Vectors just get immediately added


        fg.addLayer(m);

        if (m._setPos) {
          m._setPos(thisLayerPos);
        }
      }

      if (me._icon) {
        me._icon.className += ' leaflet-cluster-spiderfied-present';
      }

      group._forceLayout();

      group._animationStart(); // Reveal markers and spider legs.


      for (i = childMarkers.length - 1; i >= 0; i--) {
        newPos = map.layerPointToLatLng(positions[i]);
        m = childMarkers[i]; //Move marker to new position

        m._preSpiderfyLatlng = m._latlng;
        m.setLatLng(newPos);

        if (m.clusterShow) {
          m.clusterShow();
        }

        if (m._icon) {
          m._icon.className += ' leaflet-cluster-spiderfied-present';
        } // Animate leg (animation is actually delegated to CSS transition).


        if (svg) {
          leg = m._spiderLeg;
          legPath = leg._path;
          legPath.style.strokeDashoffset = 0; //legPath.style.strokeOpacity = finalLegOpacity;

          leg.setStyle({
            opacity: finalLegOpacity
          });
        }
      }

      this.setOpacity(0.3);
      group._ignoreMove = false;
      setTimeout(function () {
        group._animationEnd();

        group.fire('spiderfied', {
          cluster: me,
          markers: childMarkers
        });
      }, 200);
    },
    _animationUnspiderfy: function (zoomDetails) {
      var me = this,
          group = this._group,
          map = group._map,
          fg = group._featureGroup,
          thisLayerPos = zoomDetails ? map._latLngToNewLayerPoint(this._latlng, zoomDetails.zoom, zoomDetails.center) : map.latLngToLayerPoint(this._latlng),
          childMarkers = this.getAllChildMarkers(null, true),
          svg = L.Path.SVG,
          m,
          i,
          leg,
          legPath,
          legLength,
          nonAnimatable;
      group._ignoreMove = true;

      group._animationStart(); //Make us visible and bring the child markers back in


      this.setOpacity(1);

      for (i = childMarkers.length - 1; i >= 0; i--) {
        m = childMarkers[i]; //Marker was added to us after we were spiderfied

        if (!m._preSpiderfyLatlng) {
          continue;
        } //Close any popup on the marker first, otherwise setting the location of the marker will make the map scroll


        m.closePopup(); //Fix up the location to the real one

        m.setLatLng(m._preSpiderfyLatlng);
        delete m._preSpiderfyLatlng; //Hack override the location to be our center

        nonAnimatable = true;

        if (m._setPos) {
          m._setPos(thisLayerPos);

          nonAnimatable = false;
        }

        if (m.clusterHide) {
          m.clusterHide();
          nonAnimatable = false;
        }

        if (nonAnimatable) {
          fg.removeLayer(m);
        } // Animate the spider leg back in (animation is actually delegated to CSS transition).


        if (svg) {
          leg = m._spiderLeg;
          legPath = leg._path;
          legLength = legPath.getTotalLength() + 0.1;
          legPath.style.strokeDashoffset = legLength;
          leg.setStyle({
            opacity: 0
          });
        }
      }

      group._ignoreMove = false;
      setTimeout(function () {
        //If we have only <= one child left then that marker will be shown on the map so don't remove it!
        var stillThereChildCount = 0;

        for (i = childMarkers.length - 1; i >= 0; i--) {
          m = childMarkers[i];

          if (m._spiderLeg) {
            stillThereChildCount++;
          }
        }

        if (me._icon) {
          me._icon.className = me._icon.className.replace(' leaflet-cluster-spiderfied-present', '');
        }

        for (i = childMarkers.length - 1; i >= 0; i--) {
          m = childMarkers[i];

          if (m._icon) {
            m._icon.className = m._icon.className.replace(' leaflet-cluster-spiderfied-present', '');
          }

          if (!m._spiderLeg) {
            //Has already been unspiderfied
            continue;
          }

          if (m.clusterShow) {
            m.clusterShow();
          }

          if (m.setZIndexOffset) {
            m.setZIndexOffset(0);
          }

          if (stillThereChildCount > 1) {
            fg.removeLayer(m);
          }

          map.removeLayer(m._spiderLeg);
          delete m._spiderLeg;
        }

        group._animationEnd();

        group.fire('unspiderfied', {
          cluster: me,
          markers: childMarkers
        });
      }, 200);
    },
    //Zoom to the minimum of showing all of the child markers, or the extents of this cluster
    zoomToBounds: function (fitBoundsOptions) {
      var childClusters = this._childClusters.slice(),
          map = this._group._map,
          boundsZoom = map.getBoundsZoom(this._bounds),
          zoom = this._zoom + 1,
          mapZoom = map.getZoom(),
          i; //calculate how far we need to zoom down to see all of the markers


      while (childClusters.length > 0 && boundsZoom > zoom) {
        zoom++;
        var newClusters = [];

        for (i = 0; i < childClusters.length; i++) {
          newClusters = newClusters.concat(childClusters[i]._childClusters);
        }

        childClusters = newClusters;
      }

      if (boundsZoom > zoom) {
        console.log('var1'); // this._group._map.setView(this._latlng, zoom);
        // thus avoidung marker-disappearing-bug

        this._group._map.flyTo(this._latlng, zoom);
      } else if (boundsZoom <= mapZoom) {
        //If fitBounds wouldn't zoom us down, zoom us down instead
        console.log('var2');

        this._group._map.setView(this._latlng, mapZoom + 1);
      } else {
        console.log('var3'); // this._group._map.fitBounds(this._bounds, fitBoundsOptions);
        // even for clustered markers we want a nice animation

        this._group._map.flyToBounds(this._bounds, fitBoundsOptions);
      }
    }
  });
  L.MarkerClusterGroup.include({
    //Zoom down to show the given layer (spiderfying if necessary) then calls the callback
    zoomToShowLayer: function (layer, callback, options) {
      if (typeof callback !== 'function') {
        callback = function () {};
      }

      var showMarker = async function () {
        await (0, _emberConcurrency.timeout)(33);
        console.log('first round of animations checking..'); // TODO can be shorter ?!!

        do {
          try {
            if (this._inZoomAnimation) {
              throw new Error('nope');
            } else {}
          } catch (error) {
            console.log('not quite ready yet!', error);
            await (0, _emberConcurrency.timeout)(55);
            continue;
          }

          console.log('done with waiting .. \n now action!');
          break;
        } while (true); // setTimeout( async () => {
        //extra treamtment for Spiderified multi Markers on same Position
        // if(!layer._icon) {
        //   if (
        //     layer.__parent._zoom === 23 &&
        //     layer.__parent._childCount > 1 &&
        //     layer.__parent._childClusters.length == 0
        //   ) {
        //     console.log('is ident cooooords')
        //     this.once('spiderfied', callback, this);
        //     layer.__parent.spiderfy();
        // }
        // Regular Marker treatment
        // if ((layer._icon || layer.__parent._icon) && !this._inZoomAnimation) {


        if (layer && !this._inZoomAnimation) {
          // console.log('showMarker');
          this._map.off('moveend', showMarker, this);

          this.off('animationend', showMarker, this);
          await (0, _emberConcurrency.timeout)(5);
          await (0, _emberConcurrency.timeout)(5);

          if (!layer._icon || layer._icon) {
            // console.log('in icon layer._icon', layer._icon);
            //callback();
            if (layer.__parent._zoom === 23 && layer.__parent._childCount > 1 && layer.__parent._childClusters.length == 0) {
              this.once('spiderfied', callback, this);

              layer.__parent.spiderfy();
            } else {
              callback();
            }
          } else if (layer._icon && layer._icon.className && layer._icon.className.includes('marker-cluster')) {
            this.once('spiderfied', callback, this);

            layer.__parent.spiderfy();
          } else if (layer.__parent._icon) {
            this.once('spiderfied', callback, this);

            layer.__parent.spiderfy();
          }
        }
      };

      if (layer._icon && this._map.getBounds().contains(layer.getLatLng())) {
        //Layer is visible ond on screen, immediate return
        callback();
      } else if (layer.__parent._zoom < Math.round(this._map._zoom)) {
        //Layer should be visible at this zoom level. It must not be on screen so just pan over to it
        // this._map.on('moveend', showMarker, this);
        // // this._map.panTo(layer.getLatLng());
        // this._map.flyTo(layer.getLatLng(), 5);
        // TODO warning! potentially breaking
        // manually closing popus is never a good idea ...
        this._map.closePopup(); // await timeout(66)


        this._map.on('moveend', showMarker, this);

        this._map.on('animationend', showMarker, this); //this._map.panTo(layer.getLatLng());


        this._map.flyTo(layer.getLatLng(), layer.__parent._zoom + 1 > 5 ? layer.__parent._zoom + 1 : 5); // showMarker()

      } else {
        this._map.on('moveend', showMarker, this);

        this.on('animationend', showMarker, this); // console.log('zooming..',);
        // const zuhm =
        //   layer.__parent._zoom == 23
        //     ? layer.__parent.__parent._zoom + 1
        //     : layer.__parent._zoom + 1 > 5
        //     ? layer.__parent._zoom + 1
        //     : 5;
        //this._map.flyTo(layer.getLatLng(), 7) // showMarker()

        layer.__parent.zoomToBounds(options); // das ist gut genug. UUUUUUUUUUUUUAAAAAAAAYYYY
        // *******************************************

      }
    },
    _zoomOrSpiderfy: function (e) {
      var cluster = e.layer,
          bottomCluster = cluster;

      while (bottomCluster._childClusters.length === 1) {
        bottomCluster = bottomCluster._childClusters[0];
      }

      if (bottomCluster._zoom === this._maxZoom && bottomCluster._childCount === cluster._childCount && this.options.spiderfyOnMaxZoom) {
        // All child markers are contained in a single cluster from this._maxZoom to this cluster.
        cluster.spiderfy();
      } else if (this.options.zoomToBoundsOnClick) {
        // ! hotfix
        // TODO
        if (window.screen.width < 1025) {
          cluster.zoomToBounds();
        } else {
          cluster.zoomToBounds({
            padding: [125, 125]
          });
        }
      } // Focus the map again for keyboard users.


      if (e.originalEvent && e.originalEvent.keyCode === 13) {
        this._map._container.focus();
      }
    }
  });
});