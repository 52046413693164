define("omap/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-body-class/mixins/body-class", "honeybadger-js"], function (_exports, _applicationRouteMixin, _bodyClass, Honeybadger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // from: https://github.com/mansona/ember-body-class/blob/1df8c803d9f401f8c1a837d822283ffccce9dd73/addon/util/bodyClass.js#L1
  function addClass(klass) {
    const element = document.body;
    let existingClass = element.getAttribute('class') || '';

    if (existingClass) {
      let classes = existingClass.split(' ');

      if (classes.includes(klass)) {
        return;
      }

      element.setAttribute('class', `${existingClass} ${klass}`);
    } else {
      element.setAttribute('class', klass);
    }
  }

  function removeClass(klass) {
    const element = document.body;
    let existingClass = element.getAttribute('class');
    element.setAttribute('class', existingClass.replace(klass, ''));
  }

  var _default = Ember.Route.extend(_bodyClass.default, _applicationRouteMixin.default, {
    userRights: Ember.inject.service(),
    session: Ember.inject.service(),
    // queryParams: {
    //   category: {
    //     refreshModel: true
    //   }
    // },
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    // routeAfterAuthentication: 'projects',
    routeAfterAuthentication: 'index',
    authenticationRoute: 'check-in',

    sessionInvalidated() {
      removeClass('--logged-in');
      this.transitionTo('index');
    },

    async sessionAuthenticated() {
      let _super = this._super; // await this._loadCurrentUser();

      _super.call(this, ...arguments);

      if (!this.session.data.authenticated.user.emailVerified) {
        this.notifications.warning(`
          Please verify. We'll be sending you a verification Email shortly.`, {
          autoClear: true,
          position: 'top',
          cssClasses: 'xxx-123',
          clearDuration: 7500
        });
        this.transitionTo('index');
        return false;
      }

      this.notifications.success('Welcome, you are signed in now.', {
        autoClear: true,
        cssClasses: 'noti-123',
        clearDuration: 3800
      });
      await this.userRights.isAdmin.perform();
      addClass('--logged-in');
      await this.store.findAll('user', {
        reload: true
      });
      await this.store.findAll('marker', {
        reload: true
      });
      await this.store.findAll('image', {
        reload: true
      });
      const currentUserId = this.session.data.authenticated.user.uid;
      const currentUser = await this.store.findRecord('user', currentUserId);
      Honeybadger.setContext({
        user_id: currentUserId,
        loggedInUserID: currentUserId,
        loggedInUserName: currentUser.fullName
      });
      Honeybadger.notify(`user "${currentUser.fullName}" logged in #${currentUserId}`, 'CustomLoginLogger');
    },

    // setupController: function (controller, model) {
    //   // controller.set('modelll', 'gez');
    //   // controller.set('mod', model);
    // },
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('filteredResults', model);
    },

    model(_params) {
      console.log('running app route find All Markers'); // const markers = await this.store.findAll('marker', { reload: true });

      const allMarkers = this.store.findAll('marker', {
        reload: true
      }); // this.controllerFor('application').set('gurrgurr', m);

      return allMarkers; // const admins = await this.store.findAll('admin', { reload: true });
      // console.log('users ',users);
      // users.forEach( u => {
      //   console.log('usr', u.firstName);
      // })
      // const filteredUsers = users.filter(
      //   (user, _index, _self) => user.lat && user.lng
      // );
      // console.log('filteredU', filteredUsers);
      // const u = filteredUsers.map(u => {
      //   return {
      //     display: true,
      //     isHovered: false,
      //     lat: u.lat,
      //     lng: u.lng,
      //     type: 'sailor',
      //     isBubbleOpen: false,
      //     timestamp: '123',
      //     name: u.firstName + ' ' + u.lastName,
      //     image:
      //       'https://media1-production-mightynetworks.imgix.net/asset/11153993/AMbassador_badges__1_.png?ixlib=rails-0.3.0&auto=format&w=120&h=120&fit=crop&crop=faces',
      //     details: null,
      //     city: null,
      //     intro:
      //       '<br><i>Ocean Nomads Ambassador</i><br><br>Slow travelling oceans by sail, van & foot<br>Cultivating concious living 🌱<br><br>Eco💙Adventure<br><br>'
      //   };
      // });

      console.log('running modelll in app route'); // return u.pushObjects(sailors);
      // return users;

      return markers; // return [markers, admins]
    },

    actions: {
      reloadModelX: function () {
        console.log('refreshing model x');
        this.refresh(); // this.transitionTo('application');
      }
    }
  });

  _exports.default = _default;
});