define("omap/router", ["exports", "omap/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('about');
    this.route('gallery');
    this.route('page-1');
    this.route('page-2');
    /**
     * * mal sehen ...
     * ? Should i nest all routes under `auth`
     * ! Gut ding braucht weile  ..
        It is recommended to nest all of an application's routes that require the session to be authenticated under a common parent route:
      // app/router.js
      Router.map(function() {
        this.route('login');
        this.route('authenticated', { path: '' }, function() {
          // all routes that require the session to be authenticated
        });
      }
    **/

    this.route('check-in');
    this.route('not-found', {
      path: '/*path'
    });
    this.route('admin', function () {
      // this.route('index', { path: 'overview/all' });
      this.route('user', {
        path: 'user/:user_id'
      }, function () {// this.route('markers');
        // this.route('new-marker');
        // this.route('marker', { path: '/:marker_id' });
        // this.route('new', { path: 'new-marker'})
        // this.route('editdetails');
      }); // this.route('new-marker', { path: 'user/:user_id/new-marker' });

      this.route('new-user-and-marker'); // this.route('new-marker');

      this.route('new-marker', {
        path: 'user/:user_id/new-marker'
      });
    });
    this.route('user', function () {
      this.route('edit-images', {
        path: 'marker/:marker_id/edit-images'
      });
      this.route('edit-marker', {
        path: 'marker/:marker_id/edit-marker'
      });
      this.route('edit-user', {
        path: 'edit/user/:user_id'
      });
      this.route('new');
    });
    this.route('projects', function () {
      this.route('project', {
        path: '/:project_id'
      });
      this.route('new');
    });
    this.route('login-email-link');
    this.route('check-your-emails'); // this.route('admin', function () {
    //   this.route('user', { path: '/:user_id' });
    //   this.route('new');
    // });

    this.route('image-viewer', {
      path: 'image-viewer/:marker_id'
    });
  });
});