define("omap/components/map/basemap", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div id="basemap" {{did-insert (perform bootUpMap)}}>{{#each @allMarkers as |m index|}}{{#in-element m.domNode}}<Map::InfoWindow @marker={{m}} @isAuthenticated={{@isAuthenticated}}></Map::InfoWindow>{{/in-element}}{{/each}}</div>
  */
  {
    "id": "8d0jjgvv",
    "block": "{\"symbols\":[\"m\",\"index\",\"@isAuthenticated\",\"@allMarkers\"],\"statements\":[[11,\"div\"],[24,1,\"basemap\"],[4,[38,3],[[30,[36,2],[[35,1]],null]],null],[12],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"domNode\"]]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[8,\"map/info-window\",[],[[\"@marker\",\"@isAuthenticated\"],[[32,1],[32,3]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[13]],\"hasEval\":false,\"upvars\":[\"in-element\",\"bootUpMap\",\"perform\",\"did-insert\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "omap/components/map/basemap.hbs"
    }
  });

  let MapBasemapComponent = (_dec = Ember.inject.service, _dec2 = (0, _emberConcurrency.task)(function* () {
    // yield this.mapping.initMap.perform();
    // yield Promise.all([
    //   this.mapping.initMap.perform(),
    //   this.templater.fetchAndSetupTemplates.perform()
    // ]);
    // let markers;
    // markers = yield this.args.allMarkers;
    // console.log('yield markers in map bootup', this.args.allMarkers);
    yield this.mapping.initMap.perform();
    yield this.mapping.initialAddMarkersOnBoot.perform(this.args.allMarkers); // const nomadsOnly = this.args.markers.filter( i => i.type === "nomad" )
    // yield timeout(7444)
    // console.log('showing ony nomads',);
    // yield this.mapping.createRefreshClusterGroup.perform(nomadsOnly);
    // https://leafletjs.com/reference-1.7.1.html#map-flyto
    // improvements ideas
    // https://ghybs.github.io/Leaflet.FeatureGroup.SubGroup/examples/subGroup-markercluster-controlLayers-realworld.388.html
    // svg icon vectorizer
    // https://www.vectorizer.io/images/upload.html
  }), (_class = (_temp = class MapBasemapComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "mapping", _descriptor, this);

      _initializerDefineProperty(this, "bootUpMap", _descriptor2, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "mapping", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bootUpMap", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MapBasemapComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MapBasemapComponent);
});