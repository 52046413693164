define("omap/routes/gallery", ["exports", "emberfire/mixins/realtime-route", "emberfire/mixins/performance-route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _realtimeRoute, _performanceRoute, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_realtimeRoute.default, // PerformanceRouteMixin,
  _authenticatedRouteMixin.default, {
    authenticationRoute: "login",

    model() {
      return this.store.findAll('gallery');
    }

  });

  _exports.default = _default;
});