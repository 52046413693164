define("omap/services/mapping", ["exports", "umbrellajs/umbrella.esm", "leaflet", "leaflet.markercluster", "omap/services/custom-markercluster-patched", "omap/services/custom-map-helper-func", "mapbox-gl-leaflet", "ember-concurrency", "omap/helpers/svg-jar"], function (_exports, _umbrella, L, _leaflet2, _customMarkerclusterPatched, _customMapHelperFunc, _mapboxGlLeaflet, _emberConcurrency, _svgJar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MappingService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrency.task)(function* () {
    if (this.basemap) return; //  !! monkeypatch marker-cluster
    // console.log(' monkeypatched marker-cluster 🐒');

    const map = yield L.map('basemap', {
      tap: false,
      maxZoom: 23,
      minZoom: 2,
      maxBounds: this.maxBounds(),
      maxBoundsViscosity: 0.975,
      wheelDebounceTime: 133,
      wheelPxPerZoomLevel: 99,
      attributionControl: false // layers: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
      // layers: [
      //   L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.png',{
      //     attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      //     subdomains: 'abcd',
      //     minZoom: 1,
      //     maxZoom: 15
      //   }),
      //   L.tileLayer('https://{s}.tiles.mapbox.com/v3/tbushman.i6fcm2a5/{z}/{x}/{y}.png')
      // ]

    }).setView([47.55, 7.6], 4);
    yield L.control.scale().addTo(map);
    yield L.control.attribution({
      prefix: 'Brought to you by <a href="http://12finger.com">12finger</a> &mdash;  Vector data &copy <a href="https://www.mapbox.com/maps/">Mapbox</a>'
    }).addTo(map);
    const wrapperMap = yield L.mapboxGL({
      accessToken: 'pk.eyJ1IjoiMTJmaW5nZXIiLCJhIjoiY2tpcmxqbnc2MHFwMjJ5cnhjYnBkeHp4bCJ9.SQTor_VBo84y-lewYh6Vww',
      // get your own MapTiler token at https://cloud.maptiler.com/ or use MapBox style
      style: // 'https://api.maptiler.com/maps/basic/style.json?key=gbetYLSD5vR8MdtZ88AQ'
      //'mapbox://styles/12finger/ckirlmycz03ez19pfw86k1vmt'
      'mapbox://styles/12finger/ckjrtdrin4ung1ap3zvl3b5d7' // only Labels
      // 'mapbox://styles/12finger/ckizmdsu47twi19qmqpsmsfz6?optimize=true'

    }).addTo(map);
    const mapboxMap = wrapperMap.getMapboxMap();
    mapboxMap.on('load', function () {
      // console.log('loadedd...');
      mapboxMap.addSource('dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.terrain-rgb'
      });
      mapboxMap.addLayer({
        'id': 'hillshading',
        'source': 'dem',
        'type': 'hillshade' // insert below wood; (radon patze pick)

      }, 'wood');
    }); // yield L.map('map', {
    //   zoom: 5,
    //   center: new L.latLng([50.46187534948574, 12.726287841796877]),
    //   layers: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
    //   layers: [
    //     L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.png',{
    //       attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    //       subdomains: 'abcd',
    //       minZoom: 1,
    //       maxZoom: 15
    //     }),
    //     L.tileLayer('https://{s}.tiles.mapbox.com/v3/tbushman.i6fcm2a5/{z}/{x}/{y}.png')
    //   ]
    // });
    // Map Tiles Styles
    // Watercolor
    // https://codepen.io/localhorst/pen/BJxzGW
    // Custom icons
    // https://map.komun.org/?id=desenvolupa-ment

    const router = this.router;
    map.on('popupopen', async function (_event) {
      // var marker = _event.popup._source;
      // add listener
      setTimeout(() => {
        setTimeout(() => {
          (0, _umbrella.default)('a.non-ember-link').handle('click', function () {
            const route = (0, _umbrella.default)(this).attr('route');
            const model = (0, _umbrella.default)(this).attr('model');

            if (model) {
              router.transitionTo(route, model);
            } else {
              router.transitionTo(route);
            }
          });
        }, 33);
      }, 33);
    });
    yield Ember.set(this, 'basemap', map);
  }), _dec10 = (0, _emberConcurrency.task)(function* (allMarkers, refresh) {
    if (refresh) {
      yield this.basemapClusterGroup.clearLayers();
      yield Ember.set(this, 'model', allMarkers);
      yield Ember.set(this, 'allMarkers', []);
    } else {
      yield this.createBasemapClusterGroup();
    } // this.basemap.addLayer(yield this.createRefreshClusterGroup.perform(allMarkers));


    this.basemap.addLayer(yield this.initAllMarkersAndDomElements.perform(this.model || allMarkers));
  }), _dec11 = (0, _emberConcurrency.task)(function* (allMarkers) {
    const cGroup = yield this.basemapClusterGroup;
    yield Promise.all(allMarkers.map(async marker => {
      function iconSizes(type) {
        return type === 'basecamp' ? {
          iconSize: [42, 33],
          iconAnchor: [21, 30]
        } : {
          iconSize: [33, 39],
          iconAnchor: [16, 30]
        };
      }

      const divIcon = L.divIcon({
        type: 'div',
        className: `omap-icon__outer-container --${marker.type}`,
        ...iconSizes(marker.type),
        popupAnchor: [0, -6],
        html: `
          <div class="omap-icon__inner-container">
            <img class="omap-icon__svg" src="/images/svg/fresh/${marker.type}.svg" />
            <span class="omap-active-icon"></span>
          </div>
        `
      }); // ! hotfix
      // TODO

      const paddings = window.screen.width > 1024 ? {
        autoPanPaddingTopLeft: L.point(80, 20),
        autoPanPaddingBottomRight: L.point(400, 300)
      } : {
        autoPanPaddingTopLeft: L.point(0, 0),
        autoPanPaddingBottomRight: L.point(0, 0)
      };
      const leafletMarker = L.marker(new L.LatLng(marker.lat, marker.lng), {
        markerType: marker.type,
        title: marker.type,
        ...paddings,
        icon: divIcon
      });
      const popupPadding = window.screen.width > 1024 ? {
        autoPanPaddingTopLeft: L.point(90, 90),
        autoPanPaddingBottomRight: L.point(300, 50)
      } : {
        autoPanPaddingTopLeft: L.point(40, 90),
        autoPanPaddingBottomRight: L.point(40, 40)
      };
      leafletMarker.bindPopup(marker.domNode, popupPadding).on('popupopen', function (event) {
        // console.log("popup opened !", event);
        (0, _umbrella.default)(event.target._icon).addClass("--animate-out");
      }).on('popupclose', function (event) {
        (0, _umbrella.default)(event.target._icon).removeClass("--animate-out");
      });
      cGroup.addLayer(leafletMarker);
      this.allMarkers.pushObject({
        id: marker.id,
        leafletMarker
      });
    }));
    return cGroup;
  }).enqueue(), _dec12 = Ember._tracked, _dec13 = (0, _emberConcurrency.task)(function* (marker) {
    if (this.isAnimationRunning) return;
    this.isAnimationRunning = true; //docu
    // https://leaflet.github.io/Leaflet.markercluster/example/marker-clustering-zoomtoshowlayer.html

    const group = this.basemapClusterGroup; // TODO Experimenting with this.
    // let's see how it works in the wild
    // re-asses in a couple of weeks *now: 20.march 2021*

    group._map.closePopup(); // yield timeout(55)


    yield (0, _emberConcurrency.timeout)(125); // TODO END ((()))

    const cluster = marker.__parent;
    const isExactCoordsCrowdedCluster = Boolean(cluster._zoom === 23 && cluster._childCount > 1 && cluster._childClusters.length === 0); // console.log('isExactCoordsCrowdedCluster', isExactCoordsCrowdedCluster);

    let calculatedZoom = 12;
    if (isExactCoordsCrowdedCluster) calculatedZoom = (0, _customMapHelperFunc.getParentClusterAndZoom)(marker).zoom + 2;
    this.basemapClusterGroup.zoomToShowLayer(marker, () => {
      if (isExactCoordsCrowdedCluster) {// marker.__parent.spiderfy()
      }

      marker.openPopup();
      setTimeout(() => {
        setTimeout(() => {
          group.refreshClusters();

          group._map.fire('moveend'); // ohno - trigggers spiderfy to shut down..
          // group._map.fire('zoomend')


          this.isAnimationRunning = false;
        }, 0);
      }, 0);
    }, {
      padding: [250, 250],
      maxZoom: isExactCoordsCrowdedCluster ? calculatedZoom : cluster._zoom + 1
    });
  }), (_class = (_temp = class MappingService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "templater", _descriptor2, this);

      _initializerDefineProperty(this, "basemap", _descriptor3, this);

      _initializerDefineProperty(this, "basemapClusterGroup", _descriptor4, this);

      _initializerDefineProperty(this, "addMarkerMap", _descriptor5, this);

      _initializerDefineProperty(this, "markers", _descriptor6, this);

      _initializerDefineProperty(this, "allMarkers", _descriptor7, this);

      _initializerDefineProperty(this, "model", _descriptor8, this);

      _initializerDefineProperty(this, "initMap", _descriptor9, this);

      _initializerDefineProperty(this, "initialAddMarkersOnBoot", _descriptor10, this);

      _initializerDefineProperty(this, "initAllMarkersAndDomElements", _descriptor11, this);

      _initializerDefineProperty(this, "isAnimationRunning", _descriptor12, this);

      _initializerDefineProperty(this, "showPopup", _descriptor13, this);
    }

    maxBounds() {
      const southWest = L.latLng(-85, -277);
      const northEast = L.latLng(85, 277);
      return L.latLngBounds(southWest, northEast);
    }

    createBasemapClusterGroup() {
      let _clusterGroup;

      if (!this.basemapClusterGroup) {
        _clusterGroup = L.markerClusterGroup({
          spiderfyOnMaxZoom: true,
          showCoverageOnHover: false,
          maxClusterRadius: 55,
          iconCreateFunction: function (cluster) {
            const size = cluster.getChildCount() < 10 ? '--small' : '--medium';
            return L.divIcon({
              iconSize: [56, 56],
              className: 'custom-cluster-icon-container',
              html: ` <div class="custom-cluster-icon ${size}">
                ${_svgJar.default.compute(['kompass2b'], {
                class: 'custom-div-icon__svg'
              }).string}
                <span class="custom-div-icon-count">${cluster.getChildCount()}</span>
              </div>`
            });
          }
        });
        Ember.set(this, 'basemapClusterGroup', _clusterGroup); // cGroup = _clusterGroup;
      } else {
        console.log('else should not be the case?!'); // console.log('deleteing basemapclutergroup');
        // cGroup = this.basemapClusterGroup;
        // // TODO
        // // remove listeners
        // this.markers = [];
        // cGroup.clearLayers();
      }

      return _clusterGroup;
    } // initAllMarkersAndDomElements


    filterAndRefresh(currentActiveCategories) {
      if (this.basemapClusterGroup) this.basemapClusterGroup.clearLayers(); // const filteredMarkers = this.allMarkers.filter((item, _index, _self) => {
      //   return currentActiveCategories.includes(item.leafletMarker.options.markerType)
      // });
      // TODO rewrite to .filter  and  .pushObject

      const filteredMarkers = this.allMarkers.reduce((acc, curr) => {
        return currentActiveCategories.includes(curr.leafletMarker.options.markerType) ? acc.concat(curr.leafletMarker) : acc; // return currentActiveCategories.includes(item.leafletMarker.options.markerType)
      }, []);
      this.basemap.addLayer(this.basemapClusterGroup.addLayers(filteredMarkers));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "templater", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "basemap", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "basemapClusterGroup", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "addMarkerMap", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "markers", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "allMarkers", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "initMap", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initialAddMarkersOnBoot", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "initAllMarkersAndDomElements", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isAnimationRunning", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showPopup", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MappingService;
});