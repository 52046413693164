define("omap/adapters/application", ["exports", "emberfire/adapters/firestore", "omap/config/environment"], function (_exports, _firestore, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class FirestoreAdapter extends _firestore.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "settings", _environment.default.firebaseEmulator ? {
        host: 'localhost:8080',
        ssl: false
      } : {});
    }

  } // export { default } from '@ember-data/adapter/json-api';
  // export { default } from 'emberfire/adapters/firestore';


  _exports.default = FirestoreAdapter;
});