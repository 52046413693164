define("omap/routes/admin/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "emberfire/mixins/realtime-route"], function (_exports, _authenticatedRouteMixin, _realtimeRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _realtimeRoute.default, {
    authenticationRoute: 'check-in',

    async model() {
      return this.store.findAll('user');
    }

  });

  _exports.default = _default;
});