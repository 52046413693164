define("omap/services/user-data-handling", ["exports", "ember-concurrency", "omap/helpers/refresh-helper"], function (_exports, _emberConcurrency, _refreshHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserDataHandlingService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrency.task)(function* (markerForDeletion) {
    console.log('id to delete', markerForDeletion);

    if (yield confirm(`

          Are you sure you want to delete following marker:
          "${markerForDeletion.type} / ${markerForDeletion.city}" ?


`)) {
      // delete ...
      console.log('deleting.....');
      yield markerForDeletion.destroyRecord();
      (0, _refreshHelper.default)(this);
    } else {
      console.log('doing nothing...');
    }
  }), _dec5 = (0, _emberConcurrency.task)(function* (userForDeletion) {
    // const userForDeletion = yield this.store.peekRecord('user', uid);
    // const userForDeletion = yield this.store.peekRecord('user', uid);
    if (yield confirm(`

          Are you sure you want to delete ${userForDeletion.firstName} ${userForDeletion.lastName}?


`)) {
      // delete ...
      console.log('deleting.....');
    } else {
      return;
    }

    yield (0, _emberConcurrency.timeout)(1000);

    if (yield confirm(`
        Are you sure you R-E-A-L-L-Y want to

          ␥delete␥ ${userForDeletion.firstName} ${userForDeletion.lastName}?

        >>>>>  REALLLLLLYYYYY 💥🥵 ?  <<<<<
        There is no going back. Deletion is forever, and:
        All Markers of that person are lost!
`)) {
      // delete ...
      console.log('deleting.....');
    } else {
      return;
    }

    const markers = yield this.store.query('marker', {
      filter: {
        user: userForDeletion.id
      }
    });
    console.log('markers', markers);
    markers.invoke('destroyRecord');
    yield userForDeletion.destroyRecord();
    const functions = yield this.firebaseApp.functions();
    const response = yield functions.httpsCallable('deleteUser')({
      userForDeletionId: userForDeletion.uid
    });
    (0, _refreshHelper.default)(this);
    console.log('deletion response:', response);
  }), _dec6 = Ember._action, (_class = (_temp = class UserDataHandlingService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "firebaseApp", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "deleteMarker", _descriptor4, this);

      _initializerDefineProperty(this, "deleteUser", _descriptor5, this);
    }

    async cleanUpUnusedRecords(opts) {
      console.log('triggering cleanup');
      this.cleanUpRoutine(opts);
    }

    async cleanUpRoutine(opts) {
      console.log('optssss', opts);
      const {
        context,
        type
      } = opts;

      if (type === 'markerMod') {
        // user - created new marker
        context.model.unloadRecord();
      }

      if (type === 'adminMod') {
        // ADMIN -> user + marker creation
        await context.model.newUser.unloadRecord();
        await context.model.newMarker.unloadRecord();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "firebaseApp", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "deleteMarker", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "deleteUser", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cleanUpUnusedRecords", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "cleanUpUnusedRecords"), _class.prototype)), _class));
  _exports.default = UserDataHandlingService;
});