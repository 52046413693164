define("omap/services/templater", ["exports", "emblem", "handlebars", "ember-concurrency", "honeybadger-js"], function (_exports, _emblem, _handlebars, _emberConcurrency, Honeybadger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // import { partials } from '../templates/for-templater/templator-templates';
  let TemplaterService = (_dec = (0, _emberConcurrency.task)(function* () {
    yield Promise.all(this.listOfTemplates.map(async partialName => {
      try {
        const fetchedFile = await fetch(`/partials-for-templater/${partialName}.em`);
        const emblemPartial = await fetchedFile.text();
        const hbs = (0, _emblem.compile)(emblemPartial); // compile emblem to hbs

        _handlebars.default.registerPartial(partialName, hbs);
      } catch (error) {
        console.log('error in templater', error);
        throw error;
      }
    }));
    this.isSetupComplete = true;
  }).enqueue(), (_class = (_temp = class TemplaterService extends Ember.Service {
    constructor() {
      super(...arguments);

      _defineProperty(this, "listOfTemplates", ['popup', 'conditional-linking']);

      _defineProperty(this, "isSetupComplete", false);

      _initializerDefineProperty(this, "fetchAndSetupTemplates", _descriptor, this);

      _handlebars.default.registerHelper('partial', (templateName, context) => {
        return new _handlebars.default.SafeString(this.compileHbs(templateName, context.hash));
      });
    }

    async render(templateName, context) {
      return this.compileHbs(templateName, context);
    }

    compileHbs(templateName, context) {
      try {
        return _handlebars.default.compile(_handlebars.default.partials[templateName])(context, {
          allowProtoPropertiesByDefault: true,
          allowProtoMethodsByDefault: true
        });
      } catch (error) {
        Honeybadger.notify(error, {
          message: 'grrr, error in custom templater, *compileHbs* alarm!'
        });
        throw error;
      }
    } // fetch + initialise templates


  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetchAndSetupTemplates", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TemplaterService;
});