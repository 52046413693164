define("omap/models/marker", ["exports", "ember-data", "firebase/app", "ember-cp-validations"], function (_exports, _emberData, _app, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    city: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 3,
      // max: 22,
      // description: 'xyz',
      message: 'Please fill out 🧡 min 3 chars'
    })],
    lat: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      // integer: true,
      gte: -80,
      lte: 80,
      message: 'max ± 80°'
    })],
    lng: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      // integer: true,
      gte: -180,
      lte: 180,
      message: 'max ± 180°'
    })] // markerName: [
    //   validator('presence', {
    //     presence: true,
    //     // disabled: computed.not('model.shouldValidatemarkerName'),
    //     // dependentKeys: ['model.type']
    //   }),
    //   validator('length', {
    //     // disabled: computed.not('model.shouldValidatemarkerName'),
    //     // dependentKeys: ['model.type'],
    //     min: 3,
    //     // max: 22,
    //     // description: 'xyz',
    //     message: 'Please fill out 🧡 min 3 chars'
    //   })
    // ],
    // TODO
    // disabled for quick lauch!
    // intro: [validator('presence', true)],
    // details: [validator('presence', true)]

  }); // def = default

  const def = {
    defaultValue() {
      return null;
    }

  };
  const createdAt = {
    defaultValue() {
      return _app.default.firestore.FieldValue.serverTimestamp();
    }

  };

  var _default = _emberData.default.Model.extend(Validations, {
    session: Ember.inject.service(),
    createdAt: attr('timestamp', {
      type: 'createdAt'
    }),
    user: belongsTo('user'),
    // userEmail: computed('user', function () {
    //   return this.get('user').get('email');
    // }),
    // userFullname: computed('user', function () {
    //   return `${this.get('user').get('firstName')} ${this.get('user').get('lastName')}`;
    // }),
    // linkToUserImage: computed('user', function () {
    //   return this.get('user').get('linkToUserImage');
    // }),
    // userCity: computed('user', function () {
    //   return this.get('user').get('city');
    // }),
    // userFirstName: computed('user', function () {
    //   return this.get('user').get('firstName');
    // }),
    // userLastName: computed('user', function () {
    //   return this.get('user').get('lastName');
    // }),
    // https://fireship.io/lessons/firestore-array-queries-guide/
    participatingUsers: attr(),
    city: attr('string', def),
    lat: attr('number', {
      defaultValue() {
        return '39';
      }

    }),
    lng: attr('number', {
      defaultValue() {
        return '-41';
      }

    }),
    type: attr('string', {
      defaultValue() {
        return 'event';
      }

    }),
    images: hasMany('image'),
    markerName: attr('string', def),
    intro: attr('string', def),
    details: attr('string', def),
    imageOrder: attr({
      defaultValue() {
        return [];
      }

    }),
    // meta Attributes
    hasImages: Ember.computed('session.isAuthenticated', function () {
      if (this.session.isAuthenticated) {
        return this.images;
      } else {
        return false;
      }
    }),
    orderedImages: Ember.computed('imageOrder', function () {
      if (this.session.isAuthenticated) {
        return this.imageOrder.map(id => {
          return this.store.findRecord('image', id);
        });
      } else {
        return [];
      }
    }),
    node: null,
    domNode: Ember.computed(function () {
      if (this.node) {
        return this.node;
      } else {
        this.node = document.createElement('div');
        return this.node;
      }
    }) // shouldValidatemarkerName: computed('type', function() {
    //   return this.type == 'ship';
    // })

  });

  _exports.default = _default;
});