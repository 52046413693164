define("omap/controllers/admin/new-user-and-marker", ["exports", "ember-concurrency", "omap/helpers/refresh-helper", "umbrellajs", "papaparse"], function (_exports, _emberConcurrency, _refreshHelper, _umbrellajs, _papaparse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AdminNewUserAndMarkerController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('model.newUser.validations.isValid', 'model.newMarker.validations.isValid'), _dec9 = Ember._action, _dec10 = (0, _emberConcurrency.task)(function* (_e) {
    console.log('creating user ..'); // yield timeout(180 * 1000)

    const functions = yield this.firebaseApp.functions();
    const newData = {
      user: {
        email: this.model.newUser.email,
        firstName: this.model.newUser.firstName,
        lastName: this.model.newUser.lastName,
        nationality: this.model.newUser.nationality,
        intro: this.model.newUser.intro,
        details: this.model.newUser.details,
        linkToUserImage: this.model.newUser.linkToUserImage,
        mightyNetworksUserId: this.model.newUser.mightyNetworksUserId
      },
      marker: {
        lat: this.model.newMarker.lat,
        lng: this.model.newMarker.lng,
        city: this.model.newMarker.city,
        intro: this.model.newMarker.intro,
        details: this.model.newMarker.details,
        markerName: this.model.newMarker.markerName
      }
    };

    try {
      const response = yield functions.httpsCallable('createUser')(newData);
      console.log('respponse', response);
    } catch (error) {
      this.notifications.error(`Possible duplicate!
          Did NOT save.

          Error-Message: "${error.message}"
        `, {
        autoClear: false
      });
      return;
    }

    yield this.cleanUp(this, 'adminMod');
    (0, _refreshHelper.default)(this);
    this.transitionToRoute('admin.index');
  }), _dec11 = Ember._action, (_class = (_temp = class AdminNewUserAndMarkerController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "firebaseApp", _descriptor, this);

      _initializerDefineProperty(this, "mapping", _descriptor2, this);

      _initializerDefineProperty(this, "userDataHandling", _descriptor3, this);

      _initializerDefineProperty(this, "googleMapsApi", _descriptor4, this);

      _initializerDefineProperty(this, "notifications", _descriptor5, this);

      _defineProperty(this, "google", Ember.computed.reads('googleMapsApi.google'));

      _initializerDefineProperty(this, "parsedMembersList", _descriptor6, this);

      _initializerDefineProperty(this, "magicInput", _descriptor7, this);

      _initializerDefineProperty(this, "createUser", _descriptor8, this);
    }

    get isValid() {
      const bool = Boolean(this.model.newUser.validations.isValid && this.model.newMarker.validations.isValid);
      console.log('bool', bool);
      return this.model.newUser.validations.isValid && this.model.newMarker.validations.isValid;
    }

    async cleanUp(opts) {
      console.log('triggering cleanup');
      this.userDataHandling.cleanUpUnusedRecords(opts);
    }

    async initQuickUpload() {
      // patze hack suze upload
      // ** EXTRACT values from CSV **
      // a0   ---0 addedToMapAsNomad---
      // a3   1 memberID
      // a4   2 firstName
      // a5   3 lastName
      // a6   4 email
      // a8   5 city
      // a10  6 country
      // a12  7 geoLocation
      // a16  8 bio
      // a33  9 avatar
      // ** VIA this RBQL **
      //
      const arrOfInputs = [];
      arrOfInputs.pushObjects((0, _umbrellajs.default)('input').nodes);
      arrOfInputs.pushObjects((0, _umbrellajs.default)('textarea').nodes);
      console.log('inputs', arrOfInputs);
      (0, _umbrellajs.default)('.magic-input').on('paste', ev => {
        const clip = ev.clipboardData.getData('text').trim();

        _papaparse.default.parse(clip, {
          header: false,
          complete: results => {
            try {
              console.log(results.data);
              const data = results.data[0].map(val => val.trim());
              this.model.newUser.linkToUserImage = data[33];
              this.model.newUser.email = data[6];
              this.model.newUser.firstName = data[4];
              this.model.newUser.lastName = data[5];
              this.model.newUser.nationality = data[10];
              this.model.newUser.mightyNetworksUserId = data[3];
              this.model.newMarker.city = data[8];
              this.model.newMarker.details = data[16];
              let geocoder = new google.maps.Geocoder();
              geocoder.geocode({
                address: data[12]
              }, (results, status) => {
                if (status == 'OK') {
                  console.log('results[0].geometry.location', results[0].geometry.location);
                  console.log('results[0].geometry.location', results[0].geometry.location.lat());
                  this.model.newMarker.lat = results[0].geometry.location.lat();
                  this.model.newMarker.lng = results[0].geometry.location.lng();
                  this.mapping.addMarkerMap.setZoom(3);
                  setTimeout(() => {
                    this.mapping.addMarkerMap.panTo({
                      lat: results[0].geometry.location.lat(),
                      lng: results[0].geometry.location.lng()
                    });
                  }, 22);
                } else {// alert(
                  //   'Geocoding/retrieving GPS coords was not successful for the following reason: ' +
                  //     status
                  // );
                }
              });
            } catch (error) {
              this.notifications.error(`⚠️ ERROR. Retrieving GPS coords impossible.
                Check for incomplete/missing fields!
                They are marked red`, {
                autoClear: false,
                clearDuration: 5000
              });
            }

            this.model.newUser.validateSync();
            this.model.newMarker.validateSync();

            if (this.model.newUser.get('validations.isValid') && this.model.newMarker.get('validations.isValid')) {
              // fully okay!
              this.notifications.success(`Successfully pasted.
                🦄🦄🦄🦄🦄🦄🦄🦄🦄🦄🦄`, {
                autoClear: true,
                clearDuration: 3300
              });
            } else {
              this.notifications.warning(`Partially successful
                Check for incomplete/missing fields!
                They are marked red`, {
                autoClear: true,
                clearDuration: 4300
              });
            }
          }
        });

        setTimeout(() => {
          this.magicInput = '🌊🌊🌊🌊';
        }, 55);
        setTimeout(() => {
          this.magicInput = null;
          (0, _umbrellajs.default)('.magic-input').nodes[0].blur();
          arrOfInputs.forEach(async el => {
            el.focus();
            el.blur();
          }); // u('.fixed-bottom-button').focus();
        }, 123);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "firebaseApp", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mapping", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userDataHandling", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "googleMapsApi", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "parsedMembersList", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "magicInput", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "isValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cleanUp", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "cleanUp"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "createUser", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initQuickUpload", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "initQuickUpload"), _class.prototype)), _class));
  _exports.default = AdminNewUserAndMarkerController;
});