define("omap/templates/admin/new-user-and-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o96ROS4O",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"header-row-full\"],[12],[2,\"Create a new Nomad   → & send out a welcome eMail\"],[13],[10,\"h2\"],[12],[10,\"span\"],[12],[2,\"Nomad Data:\"],[13],[1,[30,[36,2],null,[[\"placeholder\",\"value\",\"class\"],[\"magic zone™\",[32,0,[\"magicInput\"]],\"magic-input\"]]]],[13],[8,\"user/edit-user\",[],[[\"@user\",\"@newUser\"],[[34,3,[\"newUser\"]],true]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[10,\"h2\"],[12],[2,\"Nomad Marker:\"],[13],[8,\"user/marker\",[],[[\"@marker\",\"@newUser\"],[[34,3,[\"newMarker\"]],true]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[11,\"div\"],[24,0,\"bottom-full-width-button\"],[4,[38,5],[[35,4]],null],[12],[6,[37,11],null,[[\"disabled\",\"raised\",\"onClick\",\"class\",\"willDestroy\"],[[30,[36,10],[[32,0,[\"isValid\"]]],null],true,[30,[36,9],[[32,0,[\"createUser\"]]],null],[30,[36,1],[[32,0,[\"createUser\",\"isIdle\"]],\"fixed-bottom-button button\",\"fixed-bottom-button button --performing\"],null],[30,[36,8],[[30,[36,7],[[32,0],[32,0,[\"cleanUp\"]]],null],[30,[36,6],null,[[\"context\",\"type\"],[[32,0],\"adminMod\"]]]],null]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"createUser\",\"isIdle\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Create Nomad\"]],\"parameters\":[]},{\"statements\":[[10,\"span\"],[12],[2,\"please wait…\"],[13],[1,[30,[36,0],[\"loading-spinner\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"if\",\"input\",\"model\",\"initQuickUpload\",\"did-insert\",\"hash\",\"action\",\"fn\",\"perform\",\"not\",\"paper-button\"]}",
    "meta": {
      "moduleName": "omap/templates/admin/new-user-and-marker.hbs"
    }
  });

  _exports.default = _default;
});