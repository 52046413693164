define("omap/components/marker-manager", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="marker-manager">{{#if @isAuthenticated}}{{else}}<div class="marker-manger-header">⬆ check-in to see all details! ⬆</div>{{/if}}{{#each @markers as |marker|}}<div onClick={{fn (perform @openInfoWindow) marker 'true'}} class="marker-manager-item animated"><MarkerTypeAndName @dataSet={{marker}} @isAuthenticated={{@isAuthenticated}}></MarkerTypeAndName>{{#if marker.hasImages}}<span class="color-text-flow marker-super-script"><span>i</span><span>m</span><span>a</span><span>g</span><span>e</span><span>s</span></span>{{/if}}</div>{{else}}<span>⚠️ Please choose a category to display (also: multiple)</span>{{/each}}</div>
  */
  {
    "id": "CN0c1cfb",
    "block": "{\"symbols\":[\"marker\",\"@openInfoWindow\",\"@isAuthenticated\",\"@markers\"],\"statements\":[[10,\"div\"],[14,0,\"marker-manager\"],[12],[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[],\"parameters\":[]},{\"statements\":[[10,\"div\"],[14,0,\"marker-manger-header\"],[12],[2,\"⬆ check-in to see all details! ⬆\"],[13]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,4]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[15,\"onClick\",[30,[36,1],[[30,[36,0],[[32,2]],null],[32,1],\"true\"],null]],[14,0,\"marker-manager-item animated\"],[12],[8,\"marker-type-and-name\",[],[[\"@dataSet\",\"@isAuthenticated\"],[[32,1],[32,3]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[6,[37,2],[[32,1,[\"hasImages\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"color-text-flow marker-super-script\"],[12],[10,\"span\"],[12],[2,\"i\"],[13],[10,\"span\"],[12],[2,\"m\"],[13],[10,\"span\"],[12],[2,\"a\"],[13],[10,\"span\"],[12],[2,\"g\"],[13],[10,\"span\"],[12],[2,\"e\"],[13],[10,\"span\"],[12],[2,\"s\"],[13],[13]],\"parameters\":[]}]]],[13]],\"parameters\":[1]},{\"statements\":[[10,\"span\"],[12],[2,\"⚠️ Please choose a category to display (also: multiple)\"],[13]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"perform\",\"fn\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "omap/components/marker-manager.hbs"
    }
  });

  class MarkerManagerComponent extends _component.default {}

  _exports.default = MarkerManagerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MarkerManagerComponent);
});