define("omap/templates/projects/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ys9fR3nq",
    "block": "{\"symbols\":[],\"statements\":[[8,\"projects/index\",[],[[\"@projects\"],[[34,0]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "omap/templates/projects/index.hbs"
    }
  });

  _exports.default = _default;
});