define("omap/services/identity", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    firebaseApp: Ember.inject.service(),

    async generateIdForModelName(modelName) {
      let db = await this.firebaseApp.firestore();
      let key = await db.collection((0, _emberInflector.pluralize)(modelName)).doc().id;
      return key;
    }

  });

  _exports.default = _default;
});