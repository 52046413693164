define("omap/controllers/login-email-link", ["exports", "ember-concurrency", "omap/components/login/moving-labels", "honeybadger-js", "omap/config/environment"], function (_exports, _emberConcurrency, _movingLabels, Honeybadger, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LoginEmailLinkController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* (e) {
    console.log('ev', e);
    e.preventDefault();

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    const context = this;
    const auth = yield this.firebaseApp.auth(); // run emulator
    // auth.useEmulator('http://localhost:9099');

    if (_environment.default.firebaseEmulator) {
      console.log('auth ', auth);
      auth.useEmulator('http://localhost:9099');
    }

    if (auth.isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      yield sleep(750);

      if (!this.email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        this.email = window.prompt('Please provide your email for confirmation');
      } // The client SDK will parse the code from the link for you.


      yield auth.signInWithEmailLink(this.email, window.location.href).then(function (_result) {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        console.log('sign in complete'); // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      }).catch(function (error) {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.log('error in login', error);
        setTimeout(() => {
          if (error.code === 'auth/invalid-email') {
            alert('Seems you have not entered the email for which you requested the check-in for? Please double check.');
          }

          if (error.code === 'auth/invalid-action-code') {
            alert('Your login link is expired. Please request a fresh one :)');
            context.router.transitionTo('check-in');
          }
        }, 222); // Honeybadger.notify(
        //   `user "${currentUser.fullName}" logged in #${currentUserId}`,
        //   'CustomLoginLogger'
        // );
        // throw error;

        throw new Error(`LOGIN ERROR for user ${context.email} \n Code: ${error.code}`);
      });
    }
  }), _dec7 = Ember._action, (_class = (_temp = class LoginEmailLinkController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "firebaseApp", _descriptor2, this);

      _initializerDefineProperty(this, "omapHelpers", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "email", _descriptor5, this);

      _initializerDefineProperty(this, "loginViaLink", _descriptor6, this);
    }

    initMovingLabels() {
      this.set('email', window.localStorage.getItem('emailForSignIn'));
      (0, _movingLabels.initMovingLabels)();
      (0, _movingLabels.initMovingLabels)({
        justFocus: true
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "firebaseApp", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "omapHelpers", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loginViaLink", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initMovingLabels", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "initMovingLabels"), _class.prototype)), _class));
  _exports.default = LoginEmailLinkController;
});