define("omap/components/flying-arrows", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="flying-arrows-outer"><div class="prev">{{svg-jar previous}}</div><div class="next">{{svg-jar next}}</div></div>
  */
  {
    "id": "fXmNbOlM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flying-arrows-outer\"],[12],[10,\"div\"],[14,0,\"prev\"],[12],[1,[30,[36,1],[[35,0]],null]],[13],[10,\"div\"],[14,0,\"next\"],[12],[1,[30,[36,1],[[35,2]],null]],[13],[13]],\"hasEval\":false,\"upvars\":[\"previous\",\"svg-jar\",\"next\"]}",
    "meta": {
      "moduleName": "omap/components/flying-arrows.hbs"
    }
  });

  class FlyingArrowsComponent extends _component.default {}

  _exports.default = FlyingArrowsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FlyingArrowsComponent);
});