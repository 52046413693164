define("omap/controllers/application", ["exports", "omap/controllers/light-styles", "omap/controllers/restriction", "lodash/fp/xor", "lodash/fp/includes", "uuid", "ember-concurrency", "omap/config/environment"], function (_exports, _lightStyles, _restriction, _xor, _includes, _uuid, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Ember tutorials: https://guides.emberjs.com/release/upgrading/current-edition/native-classes/
  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    userRights: Ember.inject.service(),
    mapping: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),

    init() {
      this._super(...arguments); // this.fetchUsers.perform(true);


      this.userRights.isAdmin.perform();
      this.initEmulator(); // this.filteredResults = this.model;
      // nuclear Hack  !!!
      // !!!!! // TODO fix before update to Ember 4
      // Ember.deprecate = function () {};

      Ember.deprecate = function () {
        var already_shown = [];
        return function (msg, test, opt) {
          if (test) return false;

          if (already_shown.indexOf(msg) === -1) {
            var warning = 'DEPRECATION: ' + msg;

            if (opt.url) {
              warning += ' See: ' + opt.url;
            } // console.debug(warning);


            console.log(warning);
          }

          already_shown.push(msg);
        };
      }();
    },

    filteredResults: Ember._tracked(),

    async initEmulator() {
      if (_environment.default.firebaseEmulator) {
        const functions = await this.firebaseApp.functions();
        console.log('running emulated local functions', functions);
        functions.useEmulator('localhost', 5001);
      }
    },

    isAdmin: Ember.computed.alias('userRights.isAdmin.last.value'),
    currentUser: Ember.computed('session.isAuthenticated', {
      get() {
        try {
          if (this.session.isAuthenticated) {
            return this.store.findRecord('user', this.session.data.authenticated.user.uid);
          }
        } catch (e) {
          console.log('error', e);
          return {
            fullName: 'SETTINGS'
          };
        }
      }

    }),
    updateMarkers: (0, _emberConcurrency.task)(function* () {
      console.log('performing update markers');

      try {
        const allMarkers = yield this.store.findAll('marker', {
          reload: true
        });
        yield this.mapping.initialAddMarkersOnBoot.perform(allMarkers, true);
      } catch (e) {
        // this will trigger the default `onReject`
        throw e;
      }
    }).restartable(),
    primaryMapStyle: _lightStyles.default,
    restriction: _restriction.default,
    // initally we show them all =  all activated
    currentActiveCategories: Ember.A(['vessel', 'nomad', 'event', 'basecamp']),
    r: Ember.A([]),
    lastToggledItem: {
      timestamp: null
    },
    r4: Ember.computed('model.[]', function () {
      console.log('r4');
      Ember.set(this, 'updateUUID', (0, _uuid.v4)());
    }),
    uuid: function () {
      return (0, _uuid.v4)();
    },
    uid: Ember.computed('daa', function () {
      return (0, _uuid.v4)();
    }),

    recenterMap(item) {
      const {
        lat,
        lng
      } = item;
      console.log('lat long', {
        lat,
        lng
      });
      this.gMapInstance.setZoom(3);
      this.gMapInstance.panTo({
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      });
    },

    infoBoxClearance: Ember.computed(async function () {
      return new google.maps.Size(150, 150);
    }),
    // async promisse(context, isInitialRun) {
    //   console.log('running promisse');
    //   const currentActiveCategories = this.currentActiveCategories;
    //   const markers = await this.store.findAll('marker');
    //   if(isInitialRun) {
    //     this.filteredResults = markers;
    //     return;
    //   }
    //   console.log('promisse all markers', markers);
    //   const filteredMarkerForLatLng = markers.filter(
    //     (marker, _index, _self) => marker.lat && marker.lng
    //   );
    //   // animation code
    //   // filteredMarkerForLatLng.forEach(async marker => {
    //   //   marker.setProperties({
    //   //     timestamp: uuidv4(),
    //   //     display: true,
    //   //     isHovered: false,
    //   //     isBubbleOpen: false
    //   //   });
    //   // });
    //   const filteredResults = filteredMarkerForLatLng.filter(
    //     function (marker, _index, _self) {
    //       const bool = includes(marker.type, currentActiveCategories);
    //       // shuffle inser animation (rain drops style)
    //       // if (bool) {
    //       //   // marker.lat = marker.lat + 1;
    //       //   // set(marker, 'lat', marker.lat + 1);
    //       //   // set(marker, 'display', true);
    //       //   // const times = [5, 33, 99, 213, 350, 489, 600, 750, 833];
    //       //   // const times = [5, 33, 77, 99, 133, 189, 213, 266, 300, 322, 350, 489, 600, 750, 833];
    //       //   // const times = [5, 33, 77, 99, 115, 133, 166, 189, 213, 244, 266, 300, 322, 350, 489];
    //       //   // const times = [5, 33, 77, 99, 115, 133, 166, 189, 213, 244, 266, 300, 322, 380, 400, 420, 450, 500, 550, 600];
    //       //   const times = [5, 33, 77, 99, 115, 133, 166, 189, 213, 244, 266, 300];
    //       //   if (!marker.isInsertingOnMap) {
    //       //     setTimeout(() => {
    //       //       setTimeout(() => {
    //       //         set(marker, 'isInsertingOnMap', true);
    //       //         console.log('shuffeling');
    //       //       }, shuffle(times)[0]);
    //       //     }, 5);
    //       //   }
    //       //   // res.pushObject({...user, lat: user.lat +1})
    //       // } else {
    //       //   // set(user, 'display', false);
    //       //   set(marker, 'isInsertingOnMap', false);
    //       // }
    //       return bool;
    //     }
    //   );
    //   // skipp on first initial load
    //   if (this.mapping.basemap) {
    //     this.mapping.createRefreshClusterGroup.perform(filteredResults);
    //   }
    //   return filteredResults;
    // },
    // Tasks
    toggleOpen: (0, _emberConcurrency.task)(function* (item) {
      if (!this.session.isAuthenticated) return;

      if (this.lastToggledItem.timestamp != item.timestamp) {
        this.model.forEach(element => {
          Ember.set(element, 'isBubbleOpen', false);
        });
      }

      Ember.set(this, 'lastToggledItem', item);
      Ember.set(item, 'isBubbleOpen', !item.isBubbleOpen);
      yield (0, _emberConcurrency.timeout)(125);
    }).enqueue(),
    openInfoWindow: (0, _emberConcurrency.task)(function* (item, _recenter) {
      yield (0, _emberConcurrency.timeout)(33);
      const theOne = this.mapping.allMarkers.find(m => {
        return m.id === item.id;
      });
      if (!theOne) return; // theOne.marker.togglePopup();

      yield this.mapping.showPopup.perform(theOne.leafletMarker);
      yield (0, _emberConcurrency.timeout)(444);
      return; // console.log('recenter ', recenter);
      // this.model.forEach(element => {
      //   set(element, 'isBubbleOpen', false);
      // });
      // if (recenter) this.recenterMap(item);
      // set(item, 'isBubbleOpen', true);
    }).enqueue(),
    actions: {
      x3() {
        console.log('running x23333 did insert');
        const contr = Ember.getOwner(this).lookup('controller:application');
        console.log('x3', contr.send('reloadModelX'));
      },

      test() {
        console.log('test', 'test');
        let alert = this.element.querySelector('.test');

        if (alert) {
          alert.classList.add('fade-in');
        }
      },

      pureMapClick(e) {
        let shouldStop; // we check for click on overlay, then we ignore the click (stop)

        e.event.path.forEach(pathItem => {
          if ((0, _includes.default)('anchor-middle-bottom', pathItem.classList)) shouldStop = true;
        });
        if (shouldStop) return;
        this.model.forEach(element => {
          Ember.set(element, 'isBubbleOpen', false);
        });
      },

      onGMapLoad({
        map,
        _publicAPI
      }) {
        // Do something. Save a copy of the map instance and publicAPI.
        // console.log('mapppp', map);
        Ember.set(this, 'gMapInstance', map);
      },

      back() {
        history.back();
      },

      async filter(type) {
        // this.model.forEach(element => {
        //   set(element, 'isBubbleOpen', false);
        // });
        const _newCurrentActiveCategories = (0, _xor.default)(this.currentActiveCategories, [type]);

        this.set('currentActiveCategories', _newCurrentActiveCategories); // console.log('currentActiveCategories', _newCurrentActiveCategories);

        const filteredResults = this.model.filter(item => (0, _includes.default)(item.type, _newCurrentActiveCategories)); // console.log('filteredResults', filteredResults);
        // this.set('filteredResults', filteredResults);

        const context = this;
        Ember.run.next(context, () => context.set('filteredResults', filteredResults));
        this.mapping.filterAndRefresh(_newCurrentActiveCategories); // this.mapping.createRefreshClusterGroup.perform(filteredResults);
      },

      async logout() {
        if (confirm(`
            Are you sure you want to log out ?
              .. you can request a new login-link anytime.

            Good to see you! Come back again :)

  `)) {
          if (this.session.data.authenticated.user.emailVerified) {
            this.notifications.info('Bye! See you soon.', {
              autoClear: true,
              cssClasses: 'noti-63456',
              clearDuration: 3400
            });
          }

          return this.session.invalidate();
        } else {
          console.log('aborting logout.');
        }
      }

    }
  });

  _exports.default = _default;
});