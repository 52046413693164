define("omap/templates/gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EEK3VWUu",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"gallery\"],[12],[2,\"Welcome to the Gallery\"],[13],[10,\"ul\"],[12],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"li\"],[12],[10,\"div\"],[14,0,\"title\"],[12],[1,[32,1,[\"title\"]]],[13],[10,\"div\"],[14,0,\"body\"],[12],[2,[32,1,[\"body\"]]],[13],[13]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "omap/templates/gallery.hbs"
    }
  });

  _exports.default = _default;
});