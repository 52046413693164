define("omap/controllers/restriction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // export const restrictions = {
  //   strictBounds: true,
  //   // 'latLngBoundsdd': {north: 85, south: -85, west: -180, east: 180}
  //   latLngBounds: { north: 50, south: -50, west: -180, east: 180 }
  // };
  const restriction = {
    // latLngBounds: {
    //   east: 10.49234,
    //   north: 47.808455,
    //   south: 45.81792,
    //   west: 5.95608
    // },
    // latLngBounds: { north: 85, south: -85, west: -179.999, east: 179.999 },
    latLngBounds: {
      north: 85,
      south: -85,
      west: -180,
      east: 180
    },
    strictBounds: true
  };
  var _default = restriction;
  _exports.default = _default;
});