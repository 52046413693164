define("omap/routes/check-your-emails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CheckYourEmailsRoute extends Ember.Route {}

  _exports.default = CheckYourEmailsRoute;
});