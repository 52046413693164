define("omap/templates/user/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qb9keuVP",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"full-height\"],[4,[38,3],[[30,[36,2],[[30,[36,1],[[32,0],[32,0,[\"cleanUp\"]]],null],[30,[36,0],null,[[\"context\",\"type\"],[[32,0],\"markerMod\"]]]],null]],null],[12],[8,\"user/marker\",[],[[\"@marker\"],[[34,4]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"hash\",\"action\",\"fn\",\"will-destroy\",\"model\"]}",
    "meta": {
      "moduleName": "omap/templates/user/new.hbs"
    }
  });

  _exports.default = _default;
});