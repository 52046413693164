define("omap/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9bUumUgG",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,2]],[8,\"map/basemap\",[],[[\"@allMarkers\",\"@isAuthenticated\"],[[32,0,[\"model\"]],[32,0,[\"session\",\"isAuthenticated\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[8,\"marker-manager\",[],[[\"@markers\",\"@openInfoWindow\",\"@isAuthenticated\"],[[32,0,[\"filteredResults\"]],[32,0,[\"openInfoWindow\"]],[32,0,[\"session\",\"isAuthenticated\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,[30,[36,3],null,[[\"position\",\"zindex\"],[\"top\",\"9999\"]]]],[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"saving\"],[12],[10,\"img\"],[14,\"src\",\"/images/fan.png\"],[14,0,\"fan\"],[12],[13],[13]],\"parameters\":[]}]]],[8,\"header\",[],[[\"@session\",\"@logoutAction\",\"@filterAction\",\"@backAction\",\"@currentActiveCategories\",\"@isAdmin\",\"@currentUser\"],[[34,5],[30,[36,6],[[32,0],\"logout\"],null],[30,[36,6],[[32,0],\"filter\"],null],[30,[36,6],[[32,0],\"back\"],null],[34,7],[32,0,[\"isAdmin\"]],[34,8]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[6,[37,9],[[35,5,[\"isAuthenticated\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[14,0,\"authenticated\"],[12],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[13]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[14,0,\"not-authenticated\"],[12],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[13]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"mobile-blocker\"],[12],[10,\"div\"],[14,0,\"mobile-blocker__header\"],[12],[2,\"Mobile Version coming (real) soon!\"],[13],[10,\"div\"],[14,0,\"mobile-blocker__txt\"],[12],[2,\"Please use a laptop/desktop meanwhile 🙏\"],[13],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"ember-load-remover\",\"notification-container\",\"while-loading\",\"session\",\"action\",\"currentActiveCategories\",\"currentUser\",\"if\"]}",
    "meta": {
      "moduleName": "omap/templates/application.hbs"
    }
  });

  _exports.default = _default;
});