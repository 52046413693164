define("omap/components/projects/project", ["exports", "@glimmer/component", "ky", "ember-loading/decorator"], function (_exports, _component, _ky, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#paper-button raised=true onClick=(action 'checkoutAndPrint' @model.id) class="buy-button"}}Buy and &nbsp;{{paper-icon "3d_rotation" size=18}}&nbsp;Print{{/paper-button}}<div><div><span class="type">Title:</span><span>{{@model.title}}</span></div>{{#if @model.summary}}<div><span class="type">Summary:</span><span>{{@model.summary}}</span></div>{{/if}}{{#each @model.files as |file|}}<Partials::FilePreviewer @file={{file}}></Partials::FilePreviewer>{{/each}}</div>
  */
  {
    "id": "jr6RHJSs",
    "block": "{\"symbols\":[\"file\",\"@model\"],\"statements\":[[6,[37,2],null,[[\"raised\",\"onClick\",\"class\"],[true,[30,[36,1],[[32,0],\"checkoutAndPrint\",[32,2,[\"id\"]]],null],\"buy-button\"]],[[\"default\"],[{\"statements\":[[2,\"Buy and  \"],[1,[30,[36,0],[\"3d_rotation\"],[[\"size\"],[18]]]],[2,\" Print\"]],\"parameters\":[]}]]],[10,\"div\"],[12],[10,\"div\"],[12],[10,\"span\"],[14,0,\"type\"],[12],[2,\"Title:\"],[13],[10,\"span\"],[12],[1,[32,2,[\"title\"]]],[13],[13],[6,[37,3],[[32,2,[\"summary\"]]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[12],[10,\"span\"],[14,0,\"type\"],[12],[2,\"Summary:\"],[13],[10,\"span\"],[12],[1,[32,2,[\"summary\"]]],[13],[13]],\"parameters\":[]}]]],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,2,[\"files\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[8,\"partials/file-previewer\",[],[[\"@file\"],[[32,1]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"paper-icon\",\"action\",\"paper-button\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "omap/components/projects/project.hbs"
    }
  });

  let ProjectsProjectComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, (_class = (_temp = class ProjectsProjectComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "firebaseApp", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "getSleep", _descriptor4, this);
    }

    async checkoutAndPrint(id) {
      const functions = await this.firebaseApp.functions();
      const auth = await this.firebaseApp.auth();
      console.log('id', id);
      const ff = await this.firebaseApp.firestore();
      ff.collection('files').where('project', '==', 'T44YT6mGPU7vS2jduO8p').get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, ' => ', doc.data());
        });
      }).catch(function (error) {
        console.log('Error getting documents: ', error);
      }); // .then(doc => {
      //   if (!doc.exists) {
      //     console.log('No such document!');
      //   } else {
      //     console.log('Document data:', doc.data());
      //   }
      // })
      // .catch(err => {
      //   console.log('Error getting document', err);
      // });

      const response = await functions.httpsCallable('buyAndPrint-buyAndPrint')({
        projectID: id,
        idToken: await auth.currentUser.getIdToken(
        /* forceRefresh */
        true)
      });
      console.log('respponse', response);
      const email = await auth.currentUser.email; // return await ky.post(
      //   'https://supernow-2020.firebaseio.com/api-all/buyAndPrint.json',
      //   // 'http://localhost:5001/supernow-2020/us-central1/api-all/buyAndPrint',
      //   {
      //     json: { projectId: id }
      //     // TODO temp. | trigger specific Apiary response
      //     // , headers: {
      //     //   Prefer: 'status=406'
      //     // }
      //   }
      // );

      this.notifications.success(`
      Yepp!
       Your shippment is on it´s way

       (to: ${email})
      `, {
        autoClear: true,
        cssClasses: 'noti-xx987908689',
        clearDuration: 3000
      });
      await this.getSleep.duration(850);
      this.router.transitionTo('projects.index');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "firebaseApp", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "getSleep", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "checkoutAndPrint", [_dec5, _decorator.default], Object.getOwnPropertyDescriptor(_class.prototype, "checkoutAndPrint"), _class.prototype)), _class));
  _exports.default = ProjectsProjectComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ProjectsProjectComponent);
});