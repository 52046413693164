define("omap/utils/computed-task", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedTask;

  function parseArgs(args) {
    return {
      dependentKeys: args.slice(0, -1),
      generatorFunction: args[args.length - 1]
    };
  }

  class ComputedTaskProperty extends Ember.ComputedProperty {
    constructor(...args) {
      const {
        dependentKeys,
        generatorFunction
      } = parseArgs(args);
      super(function (propertyName) {
        const taskName = `${propertyName}Task`;
        const isInitializedKeyName = `isComputedTaskInitialized-${propertyName}`;
        const isInitialized = this.get(isInitializedKeyName);
        const valueKeyName = `${taskName}.lastSuccessful.value`;

        if (!isInitialized) {
          Ember.defineProperty(this, taskName, (0, _emberConcurrency.task)(generatorFunction).restartable());
          this.addObserver(valueKeyName, () => {
            this.notifyPropertyChange(propertyName);
          });
          this.get(taskName).perform();
          const expandedKeys = [];
          dependentKeys.forEach(dependentKey => {
            Ember.expandProperties(dependentKey, property => {
              expandedKeys.push(property);
            });
          });
          expandedKeys.forEach(expandedKey => {
            this.addObserver(expandedKey, () => {
              this.get(taskName).perform();
            });
          });
          this.set(isInitializedKeyName, true);
        }

        return this.get(valueKeyName);
      });
    }

  }

  function computedTask(...args) {
    return new ComputedTaskProperty(...args);
  }
});