define("omap/components/gmappp", ["exports", "@glimmer/component", "lodash/fp/xor", "lodash/fp/includes", "omap/light-styles", "omap/restriction"], function (_exports, _component, _xor, _includes, _lightStyles, _restriction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="ember-google-map__container">{{#g-map lat="42.5407167" lng="1.5732033" zoom=3 minZoom=1 styles=primaryMapStyle mapTypeId='terrain' restriction=restriction as |g|}}{{#each filteredList as |l|}}{{#g.marker key="@index" lat=l.lat lng=l.lng class=l.timestamp onClick=(action (toggle "open" l)) as |m|}}{{#m.infoWindow isOpen=l.open as |i|}}<div class="info-window"><div class="name">{{l.name}}</div>{{#if l.img}}<img src={{l.image}}>{{/if}}<div class="div">{{{l.intro}}}</div></div>{{/m.infoWindow}}{{/g.marker}}{{/each}}{{/g-map}}</div>
  */
  {
    "id": "Vukj8eU9",
    "block": "{\"symbols\":[\"g\",\"l\",\"m\",\"i\"],\"statements\":[[10,\"div\"],[14,0,\"ember-google-map__container\"],[12],[6,[37,9],null,[[\"lat\",\"lng\",\"zoom\",\"minZoom\",\"styles\",\"mapTypeId\",\"restriction\"],[\"42.5407167\",\"1.5732033\",3,1,[35,8],\"terrain\",[35,7]]],[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"marker\"]]],[[\"key\",\"lat\",\"lng\",\"class\",\"onClick\"],[\"@index\",[32,2,[\"lat\"]],[32,2,[\"lng\"]],[32,2,[\"timestamp\"]],[30,[36,3],[[32,0],[30,[36,2],[\"open\",[32,2]],null]],null]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[32,3,[\"infoWindow\"]]],[[\"isOpen\"],[[32,2,[\"open\"]]]],[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"info-window\"],[12],[10,\"div\"],[14,0,\"name\"],[12],[1,[32,2,[\"name\"]]],[13],[6,[37,0],[[32,2,[\"img\"]]],null,[[\"default\"],[{\"statements\":[[10,\"img\"],[15,\"src\",[32,2,[\"image\"]]],[12],[13]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"div\"],[12],[2,[32,2,[\"intro\"]]],[13],[13]],\"parameters\":[4]}]]]],\"parameters\":[3]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"toggle\",\"action\",\"filteredList\",\"-track-array\",\"each\",\"restriction\",\"primaryMapStyle\",\"g-map\"]}",
    "meta": {
      "moduleName": "omap/components/gmappp.hbs"
    }
  });

  let GmapppComponent = (_dec = Ember._tracked, _dec2 = Ember.computed('args.filterFor'), _dec3 = Ember.computed.filter('chores.@each.done', function (chore, index, array) {
    return !chore.done;
  }), (_class = (_temp = class GmapppComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "lightStyle", _lightStyles.default);

      _defineProperty(this, "restriction", _restriction.default);

      _initializerDefineProperty(this, "res", _descriptor, this);

      _defineProperty(this, "allSailors", [{
        lat: '51.568161',
        lng: '4.828569',
        type: 'sailor',
        open: false,
        name: 'Suzanne van der Veeken',
        image: 'https://media1-production-mightynetworks.imgix.net/asset/11153993/AMbassador_badges__1_.png?ixlib=rails-0.3.0&auto=format&w=120&h=120&fit=crop&crop=faces',
        intro: '<br><i>Ocean Nomads Host</i><br><br>Slow travelling oceans by sail, van & fins<br>Cultivating concious living 🌱<br><br>Eco💙Adventure🧜‍♀️Blog: <a href="http://www.theOceanpreneur.com" target="_blank">Oceanpreneur.co</a><br><br>'
      }, {
        lat: '50.819818',
        lng: '-0.087891',
        type: 'sailor',
        open: false,
        name: 'Eleanor',
        image: null,
        intro: 'salty soul found under la lune'
      }, {
        lat: '48.224673',
        lng: '16.347656',
        type: 'sailor',
        open: false,
        name: 'Nina',
        image: null,
        intro: 'wants to breathe the ocean more'
      }, {
        lat: '52.553239',
        lng: '13.415798',
        type: 'sailor',
        open: false,
        name: 'Patzek',
        image: null,
        intro: 'berlin 🌊🌊🌊'
      }, {
        lat: '28.85618',
        lng: '-17.908376',
        type: 'ship',
        open: false,
        name: 'imaginary ship',
        image: null,
        intro: `this ship would be nice to exist.
              <br><br>it is available year round for adventure on and of the coasts of the canaries.
              <br><br>you only need to feed bananas to the onboard generators and it operates leaving no traces in the ocean.
              <br><br>#contact: the super fairy 🧚🏽‍♂️`
      }, {
        lat: '28.15618',
        lng: '-17.108376',
        type: 'ship',
        open: false,
        name: 'another ship',
        image: null,
        intro: `this ship would be also nice to exist.
              <br><br>it is available year round for adventure on and of the coasts of the canaries.
              <br><br>you only need to feed bananas to the onboard generators and it operates leaving no traces in the ocean.
              <br><br>#contact: the super fairy 🧚🏽‍♂️`
      }]);

      _initializerDefineProperty(this, "remainingChores", _descriptor2, this);
    }

    // @filter('allSailors', function (item, index, array) {
    //   // return !chore.done;
    //   const filteredResults = allSailors.filter((item, _index, _self) =>
    //     includes(item.type, this.filterFor)
    //   );
    //   console.log('filteredResults', filteredResults);
    //   // this.set('results', Ember.A(filteredResults));
    //   return includes(item.type, filterFor);
    // })
    // results;
    get results() {
      console.log('filterFor in comp', this.args.filterFor);
      const filteredResults = this.allSailors.filter((item, _index, _self) => (0, _includes.default)(item.type, this.args.filterFor));
      console.log('filteredResults', filteredResults); // this.set('results', Ember.A(filteredResults));
      // return includes(item.type, filterFor);
      // this.res = A([]);
      // this.res = this.res.pushObject(filteredResults);

      this.res = filteredResults; // this._updateComponent;

      return filteredResults;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "res", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "results", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "results"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "remainingChores", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GmapppComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GmapppComponent);
});