define("omap/components/image-viewer", ["exports", "@glimmer/component", "umbrellajs", "ember-concurrency", "swiper/bundle"], function (_exports, _component, _umbrellajs, _emberConcurrency, _bundle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{did-insert (perform this.initOnRender)}} class="swiper-container"><div class="swiper-wrapper">{{#each @marker.orderedImages as |i|}}<div class="swiper-slide"><img src={{i.url1400}}></div>{{/each}}</div><div class="swiper-button-prev"></div><div class="swiper-button-next"></div></div><div class="swiper-pagination"></div>
  */
  {
    "id": "kugEdDml",
    "block": "{\"symbols\":[\"i\",\"@marker\"],\"statements\":[[11,\"div\"],[24,0,\"swiper-container\"],[4,[38,1],[[30,[36,0],[[32,0,[\"initOnRender\"]]],null]],null],[12],[10,\"div\"],[14,0,\"swiper-wrapper\"],[12],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,2,[\"orderedImages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"swiper-slide\"],[12],[10,\"img\"],[15,\"src\",[32,1,[\"url1400\"]]],[12],[13],[13]],\"parameters\":[1]}]]],[13],[10,\"div\"],[14,0,\"swiper-button-prev\"],[12],[13],[10,\"div\"],[14,0,\"swiper-button-next\"],[12],[13],[13],[10,\"div\"],[14,0,\"swiper-pagination\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "omap/components/image-viewer.hbs"
    }
  });

  let ImageViewerComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = (0, _emberConcurrency.task)(function* () {
    setTimeout(() => {
      setTimeout(() => {
        this.swiper = new _bundle.default('.swiper-container', {
          slidesPerView: 1,
          spaceBetween: 30,
          loop: true,
          keyboard: true,
          // effect: 'fade',
          lazy: {
            loadPrevNext: true
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          on: {
            init: function () {
              console.log('swiper initialized');
              this.sliderLoading = false;
            }
          }
        });
      }, 666);
    }, 333);
  }), (_class = (_temp = class ImageViewerComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "swiper", _descriptor, this);

      _initializerDefineProperty(this, "sliderLoading", _descriptor2, this);

      _initializerDefineProperty(this, "initOnRender", _descriptor3, this);
    }

    willDestroy() {
      this.swiper.destroy();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swiper", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sliderLoading", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "initOnRender", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ImageViewerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ImageViewerComponent);
});