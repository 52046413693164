define("omap/templates/check-your-emails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O2kDnJQL",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"login-action-container\"],[12],[10,\"div\"],[14,0,\"check-your-emails\"],[12],[10,\"div\"],[14,0,\"back-button-container\"],[12],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"button\"],[14,0,\"button --back\"],[12],[2,\"go home\"],[13]],\"parameters\":[]}]]],[13],[10,\"div\"],[14,0,\"check-your-emails__header\"],[12],[2,\"Check your emails!\"],[13],[10,\"div\"],[14,0,\"check-your-emails__instructions\"],[12],[2,\".. and follow the link there to get signed in. 🌊🌊🌊\"],[13],[13],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "omap/templates/check-your-emails.hbs"
    }
  });

  _exports.default = _default;
});