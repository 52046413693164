define("omap/components/partials/file-previewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>{{log "is image" (starts-with @file.type "image")}}{{#if (eq @file.type "ngc")}}<div><span class="type">NGC#</span><span>{{@file.name}}</span></div>{{else if (starts-with @file.type "image")}}<div class="image-box"><img src={{@file.url}} class="projects-images"></div>{{else if (eq @file.type "audio/mp3")}}<div><span class="type">song#</span><span>{{@file.name}}</span></div>{{#if @file.url}}<audio controls src={{@file.url}} alt="tunes.."></audio>{{/if}}{{else}}<div><span class="type">unknowFileType#</span><span>{{@file.name}}</span></div>{{/if}}</div>
  */
  {
    "id": "lHVvtMAG",
    "block": "{\"symbols\":[\"@file\"],\"statements\":[[10,\"div\"],[12],[1,[30,[36,3],[\"is image\",[30,[36,2],[[32,1,[\"type\"]],\"image\"],null]],null]],[6,[37,0],[[30,[36,1],[[32,1,[\"type\"]],\"ngc\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[12],[10,\"span\"],[14,0,\"type\"],[12],[2,\"NGC#\"],[13],[10,\"span\"],[12],[1,[32,1,[\"name\"]]],[13],[13]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[30,[36,2],[[32,1,[\"type\"]],\"image\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[14,0,\"image-box\"],[12],[10,\"img\"],[15,\"src\",[32,1,[\"url\"]]],[14,0,\"projects-images\"],[12],[13],[13]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[30,[36,1],[[32,1,[\"type\"]],\"audio/mp3\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[12],[10,\"span\"],[14,0,\"type\"],[12],[2,\"song#\"],[13],[10,\"span\"],[12],[1,[32,1,[\"name\"]]],[13],[13],[6,[37,0],[[32,1,[\"url\"]]],null,[[\"default\"],[{\"statements\":[[10,\"audio\"],[14,\"controls\",\"\"],[15,\"src\",[32,1,[\"url\"]]],[14,\"alt\",\"tunes..\"],[12],[13]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[12],[10,\"span\"],[14,0,\"type\"],[12],[2,\"unknowFileType#\"],[13],[10,\"span\"],[12],[1,[32,1,[\"name\"]]],[13],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"eq\",\"starts-with\",\"log\"]}",
    "meta": {
      "moduleName": "omap/components/partials/file-previewer.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});