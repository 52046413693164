define("omap/templates/admin/user/editdetails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/0p1AvqO",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h1\"],[12],[2,\"ADMIN - Edit Nomad Details\"],[13],[8,\"user/edit-user\",[],[[\"@user\"],[[32,1]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "omap/templates/admin/user/editdetails.hbs"
    }
  });

  _exports.default = _default;
});