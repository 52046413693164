define("omap/services/custom-map-helper-func", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getParentClusterAndZoom = void 0;

  // *********************************************************************
  // custom parent finder func
  // retrieves the parent icon/marker for `isExactCoordsCrowdedCluster` type of markers
  const getParentClusterAndZoom = function (marker) {
    function _traverseParents(zoom_level, marker) {
      var vMarker = marker;

      while (vMarker && vMarker._zoom != zoom_level) {
        vMarker = vMarker.__parent;
      }

      return vMarker || marker;
    }

    const maxZoom = 23;

    const maxZoomMarkercount = _traverseParents(maxZoom, marker)._childCount;

    let markerCount, zoom;

    if (maxZoomMarkercount > 1) {
      markerCount = maxZoomMarkercount;
      zoom = maxZoom;

      while (markerCount <= maxZoomMarkercount) {
        markerCount = _traverseParents(--zoom, marker)._childCount;
        console.log('children', markerCount);
        if (zoom == 1) break; // escape hatch
      }

      const parent = _traverseParents(zoom + 3, marker).__parent;

      console.log('parent found:', parent);
      return parent ? {
        parent,
        zoom
      } : null;
    }
  };

  _exports.getParentClusterAndZoom = getParentClusterAndZoom;
});