define("omap/services/omap-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class OmapHelpersService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "emailValidation", [{
        message: 'Please double check for a correct email',
        validate: inputValue => {
          // let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          let emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // console.log('whattt????', emailPattern.test(inputValue));

          return emailPattern.test(inputValue);
        }
      }]);
    }

  }

  _exports.default = OmapHelpersService;
});