define("omap/controllers/sailors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const sailors = [{
    display: true,
    isHovered: false,
    lat: '51.568161',
    lng: '4.828569',
    type: 'sailor',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'Suzanne van der Veeken',
    image: 'https://media1-production-mightynetworks.imgix.net/asset/11153993/AMbassador_badges__1_.png?ixlib=rails-0.3.0&auto=format&w=120&h=120&fit=crop&crop=faces',
    details: null,
    city: null,
    intro: '<br><i>Ocean Nomads Host</i><br><br>Slow travelling oceans by sail, van & fins<br>Cultivating concious living 🌱<br><br>Eco💙Adventure🧜‍♀️Blog: <a href="http://www.theOceanpreneur.com" target="_blank">Oceanpreneur.co</a><br><br>'
  }, {
    display: true,
    isHovered: false,
    lat: '50.819818',
    lng: '-0.087891',
    type: 'sailor',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'Eleanor',
    image: null,
    details: null,
    city: 'Brighton, UK',
    intro: 'salty soul found under la lune'
  }, {
    display: true,
    isHovered: false,
    lat: '48.224673',
    lng: '16.347656',
    type: 'sailor',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'Nina',
    image: null,
    details: null,
    city: 'Vienna, Austria',
    intro: 'wants to breathe the ocean more'
  }, {
    display: true,
    isHovered: false,
    lat: '52.553239',
    lng: '13.415798',
    type: 'sailor',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'Patzek',
    image: null,
    details: null,
    city: 'Berlin, Germany',
    intro: 'berlin 🌊🌊🌊'
  }, {
    display: true,
    isHovered: false,
    lat: '28.85618',
    lng: '-17.908376',
    type: 'ship',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'imaginary ship',
    image: null,
    details: null,
    city: null,
    intro: `this ship would be nice to exist.
              <br><br>it is available year round for adventure on and of the coasts of the canaries.
              <br><br>you only need to feed bananas to the onboard generators and it operates leaving no traces in the ocean.
              <br><br>#contact: the super fairy 🧚🏽‍♂️`
  }, {
    display: true,
    isHovered: false,
    lat: '35.15618',
    lng: '-60.108376',
    type: 'ship',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'another ship',
    image: null,
    details: null,
    city: null,
    intro: `this ship would be also nice to exist.
              <br><br>it is available year round for adventure on and of the coasts of the canaries.
              <br><br>you only need to feed bananas to the onboard generators and it operates leaving no traces in the ocean.
              <br><br>#contact: the super fairy 🧚🏽‍♂️`
  }, {
    display: true,
    isHovered: false,
    lat: '52.3745403',
    lng: '4.8979755',
    type: 'ship',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'Windfall',
    image: null,
    details: null,
    city: null,
    intro: `Windfall! Thomatjes Boat.
              <br><br>currently under construction
              <br><br>
              <br><br><a href="https://www.instagram.com/intotheunknown.now/" target="_blank">checkout their progress on insta! 🌈</a>`
  }, {
    display: true,
    isHovered: false,
    lat: '22.15618',
    lng: '-14.108376',
    type: 'event',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'sample event',
    image: null,
    details: null,
    city: null,
    intro: `this event would be also nice to exist.`
  }, {
    display: true,
    isHovered: false,
    lat: '-1.3397668',
    lng: '-79.3666965',
    type: 'event',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'another event',
    image: null,
    details: null,
    city: null,
    intro: `lorem ipsum oceanum.`
  }, {
    display: true,
    isHovered: false,
    lat: '69.0025683',
    lng: '-50.81259472222222',
    type: 'event',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'Greenland event',
    image: null,
    details: null,
    city: null,
    intro: `lorem ipsum oceanum.`
  }, {
    display: true,
    isHovered: false,
    lat: '-2.4833826',
    lng: '117.8902853',
    type: 'event',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'Bali event',
    image: null,
    details: null,
    city: null,
    intro: `lorem ipsum oceanum.`
  }, {
    display: true,
    isHovered: false,
    lat: '38.034957',
    lng: '14.0244562',
    type: 'flottila',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'Sicily',
    image: null,
    details: "August 2018 - 2 Boats",
    city: null,
    intro: `Sicilly Flottila sailing the Eolian Islands. Two weeks, two booats.`
  }, {
    display: true,
    isHovered: false,
    lat: '44.1186078',
    lng: '15.232136',
    type: 'flottila',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'Croatia',
    image: null,
    details: 'September 2019 - 3 Boats',
    city: null,
    intro: `Croatia Flottila - Zadar`
  }, {
    display: true,
    isHovered: false,
    lat: '29.140022',
    lng: '-14.0046176',
    type: 'flottila',
    isBubbleOpen: false,
    timestamp: '123',
    name: 'Lanzarote',
    image: null,
    details: 'February 2019 - 1 Boat',
    city: null,
    intro: `Lanzarote Event`
  }];
  var _default = sailors;
  _exports.default = _default;
});