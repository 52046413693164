define("omap/routes/projects", ["exports", "emberfire/mixins/realtime-route", "emberfire/mixins/performance-route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _realtimeRoute, _performanceRoute, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_realtimeRoute.default, // PerformanceRouteMixin,
  _authenticatedRouteMixin.default, {
    authenticationRoute: 'check-in',

    async model() {
      const uid = this.session.data.authenticated.user.uid; // await this.store.findRecord('user', uid);
      // ! HAck
      // we want to load `projects` so we can display them right away.
      // *emberfire has currently no good `include` support
      // const rrr = await this.store.query('project', {
      //   query: ref => ref.where('user', '==', uid).limit(10),
      //   reload: true
      // });

      return this.store.findAll('project', {
        reload: true
      }); // return this.store.findAll('project', {reload: true});
      // return rrr
      // return this.store.query('project', {
      //   query: ref => ref.where('user', '==', uid).limit(10),
      //   reload: true
      // });
    }

  });

  _exports.default = _default;
});