define("omap/models/gallery", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    title: attr('string'),
    body: attr('string'),
    publishedAt: attr('date') // author: belongsTo('user'),
    // comments: hasMany('comments', { subcollection: true }),

  });

  _exports.default = _default;
});