define("omap/routes/login-email-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoginEmailLinkRoute extends Ember.Route {}

  _exports.default = LoginEmailLinkRoute;
});