define("omap/components/login/input-fields", ["exports", "@glimmer/component", "omap/components/login/moving-labels"], function (_exports, _component, _movingLabels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{did-insert this.initMovingLabels}} class="login-inputs">{{#if (or @isEmailSignUp @isGoogleSignUp)}}<fieldset><label for="firstName" class="login-label">firstName</label><Input type="firstName" @value={{@firstName}} class="login-firstName"></Input></fieldset><fieldset><label for="lastName" class="login-label">lastName</label><Input type="lastName" @value={{@lastName}} class="login-lastName"></Input></fieldset><fieldset><label for="addressCity" class="login-label">City</label><Input type="addressCity" @value={{@addressCity}} class="login-addressCity"></Input></fieldset>{{/if}}{{#if (not @isGoogleSignUp)}}<fieldset><label for="email" class="login-label">mail</label><Input type="email" @value={{@email}} class="login-email"></Input></fieldset><fieldset><label for="password" class="login-label">password</label><Input type="password" @value={{@password}} class="login-password"></Input></fieldset>{{/if}}</div>
  */
  {
    "id": "cXxMUdTD",
    "block": "{\"symbols\":[\"@email\",\"@password\",\"@firstName\",\"@lastName\",\"@addressCity\",\"@isGoogleSignUp\",\"@isEmailSignUp\"],\"statements\":[[11,\"div\"],[24,0,\"login-inputs\"],[4,[38,0],[[32,0,[\"initMovingLabels\"]]],null],[12],[6,[37,2],[[30,[36,1],[[32,7],[32,6]],null]],null,[[\"default\"],[{\"statements\":[[10,\"fieldset\"],[12],[10,\"label\"],[14,\"for\",\"firstName\"],[14,0,\"login-label\"],[12],[2,\"firstName\"],[13],[8,\"input\",[[24,0,\"login-firstName\"],[24,4,\"firstName\"]],[[\"@value\"],[[32,3]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[13],[10,\"fieldset\"],[12],[10,\"label\"],[14,\"for\",\"lastName\"],[14,0,\"login-label\"],[12],[2,\"lastName\"],[13],[8,\"input\",[[24,0,\"login-lastName\"],[24,4,\"lastName\"]],[[\"@value\"],[[32,4]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[13],[10,\"fieldset\"],[12],[10,\"label\"],[14,\"for\",\"addressCity\"],[14,0,\"login-label\"],[12],[2,\"City\"],[13],[8,\"input\",[[24,0,\"login-addressCity\"],[24,4,\"addressCity\"]],[[\"@value\"],[[32,5]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[13]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,3],[[32,6]],null]],null,[[\"default\"],[{\"statements\":[[10,\"fieldset\"],[12],[10,\"label\"],[14,\"for\",\"email\"],[14,0,\"login-label\"],[12],[2,\"mail\"],[13],[8,\"input\",[[24,0,\"login-email\"],[24,4,\"email\"]],[[\"@value\"],[[32,1]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[13],[10,\"fieldset\"],[12],[10,\"label\"],[14,\"for\",\"password\"],[14,0,\"login-label\"],[12],[2,\"password\"],[13],[8,\"input\",[[24,0,\"login-password\"],[24,4,\"password\"]],[[\"@value\"],[[32,2]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[13]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"or\",\"if\",\"not\"]}",
    "meta": {
      "moduleName": "omap/components/login/input-fields.hbs"
    }
  });

  let SignUpInputsComponent = (_dec = Ember._action, (_class = class SignUpInputsComponent extends _component.default {
    initMovingLabels() {
      (0, _movingLabels.initMovingLabels)();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "initMovingLabels", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "initMovingLabels"), _class.prototype)), _class));
  _exports.default = SignUpInputsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SignUpInputsComponent);
});