define("omap/transforms/timestamp", ["exports", "@ember-data/serializer/transform", "firebase/app"], function (_exports, _transform, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TimestampTransform extends _transform.default {
    // Writing - From client to server.
    serialize(value, options) {
      // return firebase.firestore.FieldValue.serverTimestamp();
      const stamp = () => {
        return _app.default.firestore.FieldValue.serverTimestamp();
      };

      return options && options.type == 'createdAt' // we pass value as-is (createdAt is being checked for pristine-ness in rules)
      ? value ? value : stamp() : // for `updatedAt` we send a `serverTimestamp`
      stamp();
    } // Reading - From server to client.
    // ! grrrr. TODO find out why this is necesary ..
    // * why no simple `return value` ?


    deserialize(value) {
      // return value.toDate();
      // try {
      //   const r = value.toDate();
      //   return r;
      // } catch(e) {
      //   console.log('nasty type error in Transform', e);
      //   return 'saving…'
      // }
      // simply returning
      // nothing of above
      return value; // console.log('log desirialize', value );
      // return value
      //   //// only new Octane Classes need this treatment
      //   // ? value.toDate()
      //   // // : Date.now().toDate();
      //   // : new Date();

      return value; //// only new Octane Classes need this treatment
      // ? value.toDate()
      // ? value;
      // : firebase.firestore.FieldValue.serverTimestamp()
      // : null // Date.now().toDate();
      // : new Dat
      // this erros in
      // try {
      //   const r = value.toDate();
      //   return r;
      // } catch(e) {
      //   console.log('nasty type error in Transform', e);
      // }
      // return undefined
      //   ? firebase.firestore.FieldValue.serverTimestamp()
      //   : undefined;
      // console.log('desirialize', value);
      // ! flickers!
      // return typeOf(value) === 'object' && value.toDate
      //   ?  value.toDate()
      //   :  value
    }

  } // import { typeOf } from '@ember/utils';
  // import Transform from 'ember-data/transform';
  // import firebase from 'firebase';
  // /**
  //  * @class Timestamp
  //  * @namespace Transform
  //  * @extends DS.Transform
  //  */
  // export default Transform.extend({
  //   /**
  //    * @override
  //    */
  //   deserialize(serialized,options) {
  //       return options && options.type == 'createdAt'
  //         ? serialized.toDate()
  //         : serialized
  //         //   ? serialized
  //         //   : firebase.firestore.FieldValue.serverTimestamp()
  //         //   // : void 0
  //     if (typeOf(serialized) === 'object' && serialized.toDate) {
  //       return serialized.toDate();
  //     }
  //     return serialized;
  //   },
  //   /**
  //    * @override
  //    */
  //   serialize(deserialized, options) {
  //     // if (typeOf(deserialized) !== 'date') {
  //     if (true) {
  //       console.log('not date');
  //       return options && options.type == 'createdAt'
  //         ? deserialized
  //         : firebase.firestore.FieldValue.serverTimestamp();
  //       return firebase.firestore.FieldValue.serverTimestamp();
  //     }
  //     return deserialized;
  //   }
  // });


  _exports.default = TimestampTransform;
});