define("omap/components/paper-link-engine", ["exports", "ember-paper-link/components/paper-link-engine"], function (_exports, _paperLinkEngine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _paperLinkEngine.default;
    }
  });
});