define("omap/templates/page-two", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FoLd1O2J",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"More coontente goz hiiier.\"],[13],[10,\"div\"],[12],[2,\"More coontente goz hiiier.\"],[13],[10,\"div\"],[12],[2,\"More coontente goz hiiier.\"],[13],[10,\"div\"],[12],[2,\"More coontente goz hiiier.\"],[13],[10,\"div\"],[12],[2,\"More coontente goz hiiier.\"],[13],[10,\"div\"],[12],[2,\"More coontente goz hiiier.\"],[13],[10,\"div\"],[12],[2,\"More coontente goz hiiier.\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "omap/templates/page-two.hbs"
    }
  });

  _exports.default = _default;
});