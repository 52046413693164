define("omap/templates/user/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ziXFifeW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header-row-full --user-settings\"],[12],[10,\"h1\"],[12],[10,\"span\"],[12],[2,\"My Markers\"],[13],[8,\"link-to\",[[24,0,\"button no-link-style\"]],[[\"@route\"],[\"user.new\"]],[[\"default\"],[{\"statements\":[[2,\"New Marker\"]],\"parameters\":[]}]]],[13],[13],[8,\"partials/marker-index\",[],[[\"@model\"],[[34,0]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "omap/templates/user/index.hbs"
    }
  });

  _exports.default = _default;
});