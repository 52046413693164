define("omap/components/admin/users", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "3s3mbgEF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "omap/components/admin/users.hbs"
    }
  });

  class AdminUsersComponent extends _component.default {}

  _exports.default = AdminUsersComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AdminUsersComponent);
});