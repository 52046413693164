define("omap/components/u-i/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!-- https://discuss.emberjs.com/t/octane-and-select/18195/2 --}}
  
  <select
    class="u-i-select"
    ...attributes
    {{on "change" (pick "target.value" @onChange)}}
  >
    {{yield (hash option=(component "u-i/select/option" currentValue=@value))}}
  </select>
  */
  {
    "id": "RYfLQhvA",
    "block": "{\"symbols\":[\"&attrs\",\"@onChange\",\"@value\",\"&default\"],\"statements\":[[2,\"\\n\"],[11,\"select\"],[24,0,\"u-i-select\"],[17,1],[4,[38,1],[\"change\",[30,[36,0],[\"target.value\",[32,2]],null]],null],[12],[2,\"\\n  \"],[18,4,[[30,[36,3],null,[[\"option\"],[[30,[36,2],[\"u-i/select/option\"],[[\"currentValue\"],[[32,3]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"pick\",\"on\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "omap/components/u-i/select.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});