define("omap/components/partials/enriched-marker-city", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @isInfoWindow}}<span class="enriched-marker-city">{{yield}}<span class="info-window__city">{{@city}}</span></span>{{else}}{{yield}}{{/if}}
  */
  {
    "id": "zjlcKcD7",
    "block": "{\"symbols\":[\"&default\",\"@city\",\"@isInfoWindow\"],\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"span\"],[14,0,\"enriched-marker-city\"],[12],[18,1,null],[10,\"span\"],[14,0,\"info-window__city\"],[12],[1,[32,2]],[13],[13]],\"parameters\":[]},{\"statements\":[[18,1,null]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "omap/components/partials/enriched-marker-city.hbs"
    }
  });

  class PartialsEnrichedMarkerCityComponent extends _component.default {}

  _exports.default = PartialsEnrichedMarkerCityComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PartialsEnrichedMarkerCityComponent);
});