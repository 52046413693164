define("omap/routes/image-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ImageViewerRoute extends Ember.Route {
    model({
      marker_id
    }) {
      return this.store.findRecord('marker', marker_id);
    }

  }

  _exports.default = ImageViewerRoute;
});