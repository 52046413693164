define("omap/components/u-i/select/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <option value={{@value}} selected={{if (eq @currentValue @value) true false}}>{{@label}}</option>
  
  */
  {
    "id": "q67V/GGE",
    "block": "{\"symbols\":[\"@value\",\"@currentValue\",\"@label\"],\"statements\":[[10,\"option\"],[15,2,[32,1]],[15,\"selected\",[30,[36,1],[[30,[36,0],[[32,2],[32,1]],null],true,false],null]],[12],[1,[32,3]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "omap/components/u-i/select/option.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});