define("omap/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DmaHMks1",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[12],[2,\"The route requested could not be loaded. Are you logged in ?!\"],[13],[10,\"div\"],[12],[2,\"Error: \"],[10,\"code\"],[12],[1,[32,1]],[13],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "omap/templates/error.hbs"
    }
  });

  _exports.default = _default;
});