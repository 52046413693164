define("omap/templates/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s3NAiIv6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"jumbo\"],[12],[10,\"div\"],[14,0,\"right tomster\"],[12],[13],[10,\"h2\"],[14,0,\"sub-header-row-full --slim\"],[12],[2,\"Ocean Nomads\"],[13],[10,\"p\"],[12],[2,\"We are a global community of impact-driven oceanminded travellers,  entrepreneurs, creatives, sailors, (free)divers, (kite)surfers, overland explorers, and curious salty souls passionate about ocean living, travel and conservation.\"],[13],[10,\"p\"],[12],[2,\"Pin yourself on the map. Explore where the ocean nomads, vessels. events, vehicles, and basecamps are.\"],[13],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "omap/templates/about.hbs"
    }
  });

  _exports.default = _default;
});