define("omap/components/login/moving-labels", ["exports", "umbrellajs"], function (_exports, _umbrellajs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initMovingLabels = void 0;

  const initMovingLabels = function ({
    justFocus = false
  } = {}) {
    // * full selector names including the `.dot`
    const label = '.login-label';
    const container = 'fieldset'; // * these names intended for adding/removing.
    // * thus: NO `.dot`

    const labelMoved = 'login-label--moved';
    const labelActive = '--active';
    const notEmpty = 'not-empty'; // helper
    // umbrella does not have `.prev()`
    // so we hand-code it.

    _umbrellajs.default.prototype.prev = function prev(label) {
      return this.closest(container).find(label);
    }; // we handle the "justFocus"-casse


    if (justFocus) {
      const singleOrigin = (0, _umbrellajs.default)('.login-email');
      singleOrigin.prev(label).addClass(labelMoved);
      singleOrigin.parent().addClass(labelActive);
      return;
    } //end special handling


    ['.login-email', '.login-password', '.login-firstName', '.login-lastName', '.login-addressCity'].forEach(item => {
      const origin = (0, _umbrellajs.default)(item);
      origin.on('focus', () => {
        origin.prev(label).addClass(labelMoved);
        origin.parent().addClass(labelActive);
      });
      origin.on('blur', () => {
        origin.prev(label).removeClass(labelMoved);
        origin.parent().removeClass(labelActive);
      });
      origin.on('input', event => {
        return event.target.value ? origin.prev(label).addClass(notEmpty) : origin.prev(label).removeClass(notEmpty);
      });
      origin.first().value ? origin.prev(label).addClass(notEmpty) : origin.prev(label).removeClass(notEmpty);
    });
  };

  _exports.initMovingLabels = initMovingLabels;
});