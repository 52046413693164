define("omap/components/projects/projects", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="welcome">Welcome to our gallery of Projects.</div>{{outlet}}
  */
  {
    "id": "EPbyFFQt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"welcome\"],[12],[2,\"Welcome to our gallery of Projects.\"],[13],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "omap/components/projects/projects.hbs"
    }
  });

  class ProjectsProjectsComponent extends _component.default {}

  _exports.default = ProjectsProjectsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ProjectsProjectsComponent);
});