define("omap/models/user", ["exports", "ember-data", "firebase/app", "ember-cp-validations"], function (_exports, _emberData, _app, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // patricksmail@gmx.net (Standardadresse)
  // leg@die-optimisten.net
  // leg@volloeko.de
  // leg@wolke7.net
  // Patrick.Kozma@gmx.net
  // imgurlsss
  // eleanor
  // https://media1-production-mightynetworks.imgix.net/asset/11522355/Eleanor_-_Host_badge.png?ixlib=rails-0.3.0&auto=format&w=600&h=600&fit=crop&crop=faces
  // pete
  // https://media1-production-mightynetworks.imgix.net/asset/12466249/profilepic.jpeg?ixlib=rails-0.3.0&fm=jpg&q=100&auto=format&w=600&h=600&fit=crop&crop=faces
  // nina
  // https://media1-production-mightynetworks.imgix.net/asset/11164917/5.png.jpg?ixlib=rails-0.3.0&fm=jpg&q=100&auto=format&w=600&h=600&fit=crop&crop=faces
  // icke patze
  // https://media1-production-mightynetworks.imgix.net/asset/11415844/portrait.jpeg?ixlib=rails-0.3.0&fm=jpg&q=100&auto=format&w=600&h=600&fit=crop&crop=faces
  // suzanne
  // https://media1-production-mightynetworks.imgix.net/asset/11153993/AMbassador_badges__1_.png?ixlib=rails-0.3.0&auto=format&w=600&h=600&fit=crop&crop=faces
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('format', {
      type: 'email',
      message: 'only full&correct email'
    })],
    firstName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 1,
      // max: 22,
      // description: 'xyz',
      message: 'Please fill out 🧡 min 3 chars'
    })],
    lastName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 1,
      // max: 22,
      message: 'Please fill out 🧡 min 3 chars'
    })],
    nationality: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 1,
      // max: 22,
      message: 'Please fill out 🧡 min 3 chars'
    })],
    // intro: [
    //   validator('presence', true),
    //   validator('length', {
    //     min: 3,
    //     // max: 22,
    //     // description: 'xyz',
    //     message: 'Please provide'
    //   })
    // ],
    // details: [
    //   validator('presence', true),
    //   validator('length', {
    //     min: 3,
    //     // max: 22,
    //     message: 'Please fill out 🧡 min 3 chars'
    //   })
    // ],
    linkToUserImage: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      // max: 22,
      message: 'Please provide'
    })],
    mightyNetworksUserId: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gt: 10000,
      lte: 999999999,
      message: 'Numbers Only! min 6 chars ?!'
    })]
  });
  const def = {
    // def = default
    defaultValue() {
      return null;
    }

  };
  const createdAt = {
    defaultValue() {
      return _app.default.firestore.FieldValue.serverTimestamp();
    }

  };

  var _default = _emberData.default.Model.extend(Validations, {
    // createdAt: DS.attr(createdAt),
    // createdAt: attr('timestamp', { type: 'createdAt', defaultValue: createdAt }),
    createdAt: attr('timestamp', {
      type: 'createdAt',
      default: 'CREATE_NOW' // defaultValue: () => {
      // }
      //   return firebase.firestore.FieldValue.serverTimestamp();

    }),
    updatedAt: attr('timestamp', def),
    // updatedAt: attr('timestamp', {
    //   type: 'createdAt',
    //   defaultValue: () => {
    //     return firebase.firestore.FieldValue.serverTimestamp();
    //   }
    // }),
    email: attr('string', def),
    firstName: attr('string', def),
    lastName: attr('string', def),
    nationality: attr('string', def),
    // intro: attr('string', def),
    // details: attr('string', def),
    linkToUserImage: attr('string', def),
    // lat: attr('string', def),
    // lng: attr('string', def),
    // type: attr('string', def),
    mightyNetworksUserId: attr('number', def),
    markers: hasMany('marker'),
    firstMarker: Ember.computed('markers', function () {
      return this.get('markers').firstObject.id;
    }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      if (this.get('firstName') && this.get('lastName')) {
        return this.get('firstName') + ' ' + this.get('lastName');
      } else {
        return null;
      }
    }) // projects: hasMany('project', { inverse: 'projects', async: true })
    // projects: hasMany('project', { async: false })
    // projects: hasMany('project')

  }); // import Model, { attr } from '@ember-data/model';
  // import firebase from 'firebase';
  // export default class UserModel extends Model {
  //   @attr(
  //     // 'timestamp',
  //     // 'date',
  //     {
  //       // type: 'createdAt',
  //       defaultValue: () => {
  //         return firebase.firestore.FieldValue.serverTimestamp();
  //       }
  //     }
  //   ) createdAt;
  //   @attr('timestamp', { defaultValue: undefined }) updatedAt;
  //   // @attr({ defaultValue: null }) updatedAt;
  //   @attr('string', { defaultValue: null }) firstName;
  //   @attr('string', { defaultValue: null }) lastName;
  //   @attr('string', { defaultValue: null }) addressCity;
  //   @attr('string', { defaultValue: null }) addressStreet;
  //   @attr('string', { defaultValue: null }) addressHouseNumber;
  //   @attr('string', { defaultValue: null }) addressPostalCode;
  //   @attr('string', { defaultValue: "36456435" }) birthday;
  // }


  _exports.default = _default;
});