define("omap/templates/login-email-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/sxAZmzc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"login-action-container\"],[12],[10,\"div\"],[14,0,\"back-button-container\"],[12],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"button\"],[14,0,\"button --back\"],[12],[2,\"home\"],[13]],\"parameters\":[]}]]],[13],[11,\"div\"],[24,0,\"login-inputs\"],[4,[38,1],[[32,0,[\"initMovingLabels\"]]],null],[12],[10,\"form\"],[12],[10,\"fieldset\"],[12],[10,\"div\"],[12],[10,\"div\"],[14,0,\"login-link-header\"],[12],[2,\"Please re-enter your email for verification ☀️\"],[13],[13],[1,[30,[36,4],null,[[\"label\",\"placeholder\",\"value\",\"onChange\",\"class\",\"customValidations\"],[\"email\",\"\",[32,0,[\"email\"]],[30,[36,3],[[32,0],[30,[36,2],[[32,0,[\"email\"]]],null]],null],\"create-user--email\",[32,0,[\"omapHelpers\",\"emailValidation\"]]]]]],[10,\"button\"],[15,\"onClick\",[30,[36,5],[[32,0,[\"loginViaLink\"]]],null]],[15,\"disabled\",[32,0,[\"loginViaLink\",\"isRunning\"]]],[14,0,\"button --login-email-link\"],[12],[6,[37,6],[[32,0,[\"loginViaLink\",\"isIdle\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Check Me In!\"]],\"parameters\":[]},{\"statements\":[[1,[30,[36,0],[\"loading-spinner\"],null]],[10,\"div\"],[14,0,\"span\"],[12],[2,\"this should be fast :)\"],[13]],\"parameters\":[]}]]],[13],[13],[13],[13],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"did-insert\",\"mut\",\"action\",\"paper-input\",\"perform\",\"if\"]}",
    "meta": {
      "moduleName": "omap/templates/login-email-link.hbs"
    }
  });

  _exports.default = _default;
});