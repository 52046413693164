define("omap/components/overlay", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{did-insert this.fadeIn}} class="test">
    hey world
  </div>
  */
  {
    "id": "K9CY6FDd",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"test\"],[4,[38,0],[[32,0,[\"fadeIn\"]]],null],[12],[2,\"\\n  hey world\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "omap/components/overlay.hbs"
    }
  });

  let OverlayComponent = (_dec = Ember._action, (_class = class OverlayComponent extends _component.default {
    fadeIn(element) {
      setTimeout(() => {
        element.classList.add('fade-in');
      }, 3000);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "fadeIn", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "fadeIn"), _class.prototype)), _class));
  _exports.default = OverlayComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OverlayComponent);
});