define("omap/models/image", ["exports", "ember-data", "firebase/app"], function (_exports, _emberData, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  // def = default
  const def = {
    defaultValue() {
      return null;
    }

  };
  const createdAt = {
    defaultValue() {
      return _app.default.firestore.FieldValue.serverTimestamp();
    }

  };

  var _default = _emberData.default.Model.extend({
    createdAt: attr('timestamp', {
      type: 'createdAt'
    }),
    // createdAt: attr('timestamp', { type: 'createdAt', defaultValue: createdAt }),
    type: attr('string'),
    description: attr('string', def),
    url: attr('string', def),
    marker: belongsTo('marker'),
    name: attr('string'),
    // resized: attr('custom-map'),
    url300: attr('string', def),
    // url500: attr('string', def),
    url1400: attr('string', def) // url2400: attr('string', def)

  });

  _exports.default = _default;
});