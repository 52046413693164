define("omap/templates/admin/new-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hweNXfal",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"header-row-full\"],[12],[1,[30,[36,0],[\"Admin - Create a New Marker for Nomad: \",[32,0,[\"model\",\"user\",\"email\"]]],null]],[13],[8,\"user/marker\",[],[[\"@marker\"],[[32,0,[\"model\",\"newMarker\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "meta": {
      "moduleName": "omap/templates/admin/new-marker.hbs"
    }
  });

  _exports.default = _default;
});