define("omap/helpers/refresh-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    // setTimeout(() => {
    //   getOwner(context).lookup('controller:application').updateMarkers.perform();
    //   setTimeout(() => {
    //     getOwner(context).lookup('controller:application').updateMarkers.perform();
    //     setTimeout(() => {
    //       getOwner(context).lookup('controller:application').updateMarkers.perform();
    //       setTimeout(() => {
    //         getOwner(context).lookup('controller:application').updateMarkers.perform();
    //         setTimeout(() => {
    //           getOwner(context)
    //             .lookup('controller:application')
    //             .updateMarkers.perform();
    //           console.log('final timeout. ✅');
    //         }, 800);
    //       }, 800);
    //     }, 700);
    //   }, 700);
    // }, 750);
    setTimeout(() => {
      Ember.getOwner(context).lookup('controller:application').updateMarkers.perform();
      setTimeout(() => {
        Ember.getOwner(context).lookup('controller:application').updateMarkers.perform();
        setTimeout(() => {
          Ember.getOwner(context).lookup('controller:application').updateMarkers.perform();
          console.log('final timeout. ✅');
        }, 1000);
      }, 1250);
    }, 888);
  }
});