define("omap/routes/admin/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdminUserRoute extends Ember.Route {
    // async model({ user_id }) {
    //   console.log('id in user admin', user_id);
    //   // return this.store.findRecord('user', user_id);
    //   return this.store.query('marker', {
    //     filter: {
    //       user: user_id
    //     }
    //   });
    // }
    model({
      user_id
    }) {
      return Ember.RSVP.hash({
        user: this.store.findRecord('user', user_id),
        markers: this.store.query('marker', {
          filter: {
            user: user_id
          }
        })
      });
    }

  }

  _exports.default = AdminUserRoute;
});