define("omap/components/partials/conditional-linking", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @shouldLink}}<LinkTo @route={{@route}} @model={{@model}} @class={{@class}}>{{yield}}</LinkTo>{{else}}{{yield}}{{/if}}
  */
  {
    "id": "Bdm/Oj5f",
    "block": "{\"symbols\":[\"&default\",\"@route\",\"@model\",\"@class\",\"@shouldLink\"],\"statements\":[[6,[37,0],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@model\",\"@class\"],[[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[18,1,null]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[18,1,null]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "omap/components/partials/conditional-linking.hbs"
    }
  });

  class PartialsConditionalLinkingComponent extends _component.default {}

  _exports.default = PartialsConditionalLinkingComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PartialsConditionalLinkingComponent);
});