define("omap/routes/user/edit-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserEditMarkerRoute extends Ember.Route {}

  _exports.default = UserEditMarkerRoute;
});