define("omap/components/omap-loading-spinner", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
     {{!-- .scene --}}
     {{!-- .box --}}
       {{!-- .box__face.box__face--front front --}}
       {{!-- .box__face.box__face--back back --}}
       {{!-- .box__face.box__face--right right --}}
       {{!-- .box__face.box__face--left Ocean --}}
       {{!-- .box__face.box__face--top Nomads --}}
       {{!-- .box__face.box__face--bottom Map --}}
  
  
  
  <div class="scene">
    <div class="box omap-loading-rotate">
      <div class="box__face box__face--front">Map🌍</div>
      <div class="box__face box__face--back">{{!--back --}}</div>
      <div class="box__face box__face--right">{{!--right --}}</div>
      <div class="box__face box__face--left">
        <span>Nomads</span>
      </div>
      <div class="box__face box__face--top">Ocean</div>
      <div class="box__face box__face--bottom">{{!--bottom --}}</div>
    </div>
  </div>
  */
  {
    "id": "ee5s4Zaj",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"scene\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"box omap-loading-rotate\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"box__face box__face--front\"],[12],[2,\"Map🌍\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"box__face box__face--back\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"box__face box__face--right\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"box__face box__face--left\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"Nomads\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"box__face box__face--top\"],[12],[2,\"Ocean\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"box__face box__face--bottom\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "omap/components/omap-loading-spinner.hbs"
    }
  });

  class OmapLoadingSpinnerComponent extends _component.default {}

  _exports.default = OmapLoadingSpinnerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OmapLoadingSpinnerComponent);
});