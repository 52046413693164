define("omap/services/get-sleep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class GetSleepService extends Ember.Service {
    duration(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

  }

  _exports.default = GetSleepService;
});