define("omap/templates/admin/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SXRHkijV",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"header-row-full\"],[12],[10,\"h1\"],[12],[2,\"Admin - Settings    Nomad:   \"],[10,\"span\"],[12],[1,[32,1,[\"user\",\"email\"]]],[13],[13],[13],[10,\"div\"],[14,0,\"sub-header-row-full\"],[12],[10,\"h2\"],[12],[2,\"Nomad Details\"],[13],[10,\"button\"],[15,\"onClick\",[30,[36,2],[[30,[36,1],[[32,0,[\"updateUser\"]]],null],[32,0,[\"model\",\"user\"]]],null]],[15,\"disabled\",[30,[36,3],[[32,0,[\"updateUser\",\"isRunning\"]],[32,0,[\"model\",\"user\",\"validations\",\"errors\",\"length\"]]],null]],[15,0,[31,[\"button --update-user-details no-link-style \",[30,[36,4],[[32,0,[\"isUserDirty\"]],\"--is-dirty\"],null]]]],[12],[6,[37,4],[[32,0,[\"updateUser\",\"isIdle\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Update Details\"]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[14,0,\"span\"],[12],[2,\"saving…\"],[13],[1,[30,[36,0],[\"loading-spinner\"],null]]],\"parameters\":[]}]]],[13],[13],[8,\"user/edit-user\",[],[[\"@user\",\"@adminAction\"],[[32,0,[\"model\",\"user\"]],\"edit\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"sub-header-row-full\"],[12],[10,\"h2\"],[12],[2,\"Markers\"],[13],[8,\"link-to\",[],[[\"@route\",\"@model\",\"@class\"],[\"admin.new-marker\",[32,0,[\"model\",\"user\",\"id\"]],\"button no-link-style user-settings__new-marker-btn\"]],[[\"default\"],[{\"statements\":[[2,\"New Marker\"]],\"parameters\":[]}]]],[13],[8,\"partials/marker-index\",[],[[\"@model\"],[[32,0,[\"model\",\"markers\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"perform\",\"fn\",\"or\",\"if\"]}",
    "meta": {
      "moduleName": "omap/templates/admin/user.hbs"
    }
  });

  _exports.default = _default;
});