define("omap/components/projects/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo @route="projects.new" class="projects">{{#paper-button accent=true raised=true primary=true}}New Project{{/paper-button}}</LinkTo>{{#if @projects}}<h3>All current Projects:</h3>{{else}}<h3>No projects yet :( go, and create some!</h3>{{/if}}<div class="projects">{{#each @projects as |p|}}<LinkTo @route="projects.project" @model={{p.id}} @class="projects-index"><div><div><span class="type">Title#</span><span>{{p.title}}</span></div><div><span class="type">id#</span><span>{{p.id}}</span></div>{{#each p.files as |file|}}{{log "FilePreviewer" file}}<Partials::FilePreviewer @file={{file}}></Partials::FilePreviewer>{{/each}}</div></LinkTo>{{/each}}</div>
  */
  {
    "id": "Y5tz3mUs",
    "block": "{\"symbols\":[\"p\",\"file\",\"@projects\"],\"statements\":[[8,\"link-to\",[[24,0,\"projects\"]],[[\"@route\"],[\"projects.new\"]],[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"accent\",\"raised\",\"primary\"],[true,true,true]],[[\"default\"],[{\"statements\":[[2,\"New Project\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,4],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"h3\"],[12],[2,\"All current Projects:\"],[13]],\"parameters\":[]},{\"statements\":[[10,\"h3\"],[12],[2,\"No projects yet :( go, and create some!\"],[13]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"projects\"],[12],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@model\",\"@class\"],[\"projects.project\",[32,1,[\"id\"]],\"projects-index\"]],[[\"default\"],[{\"statements\":[[10,\"div\"],[12],[10,\"div\"],[12],[10,\"span\"],[14,0,\"type\"],[12],[2,\"Title#\"],[13],[10,\"span\"],[12],[1,[32,1,[\"title\"]]],[13],[13],[10,\"div\"],[12],[10,\"span\"],[14,0,\"type\"],[12],[2,\"id#\"],[13],[10,\"span\"],[12],[1,[32,1,[\"id\"]]],[13],[13],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1,[\"files\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"FilePreviewer\",[32,2]],null]],[8,\"partials/file-previewer\",[],[[\"@file\"],[[32,2]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[13]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"log\",\"-track-array\",\"each\",\"paper-button\",\"if\"]}",
    "meta": {
      "moduleName": "omap/components/projects/index.hbs"
    }
  });

  class ProjectsIndexComponent extends _component.default {}

  _exports.default = ProjectsIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ProjectsIndexComponent);
});