define("omap/templates/user/edit-images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LVKNtuER",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"user/images\",[],[[\"@marker\"],[[32,1]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "omap/templates/user/edit-images.hbs"
    }
  });

  _exports.default = _default;
});